<template>
    <div>
        <el-button size="small" class="btu-color" @click="create()">
            {{ $t("message.create") }}
        </el-button>
        <el-dropdown class="setting-cheek ml-2" :hide-on-click="false">
            <el-button
                class="padding_none"
                size="small"
                icon="el-icon-open"
            ></el-button>
            <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                    v-for="(column, index) in columns"
                    :key="'drop' + index"
                >
                    <el-checkbox
                        :checked="column.show"
                        @change="check(column.column, $event)"
                        >{{ column.title }}
                    </el-checkbox>
                </el-dropdown-item>
            </el-dropdown-menu>
        </el-dropdown>
    </div>
</template>
<script>
export default {
    props: {
        columns: {
            type: Object,
        },
    },
    methods: {
        check: function (column, event) {
            this.$emit("c-change", {
                key: column,
                value: event,
            });
        },
        create() {
            this.$emit("c-create", true);
        },
    },
};
</script>
<style scoped>
.el-dropdown-menu__item > label {
    margin-bottom: 0.1rem !important;
}

.el-dropdown-menu {
    max-height: 720px !important;
    overflow-y: scroll;
}
.el-dropdown-menu__item,
.el-checkbox__label,
.el-checkbox {
    font-size: 12px !important;
}
</style>
