<template>
    <div class="create-deal-modal" v-loading="loadingData">
        <div class="app-modal__box">
            <div class="app-modal__header p-5 d-flex f-between w-100">
                <p class="large--title m-0">
                    <span v-if="selectedDealForUpdate.id" class="mr-1">
                        {{ ('№ '+ selectedDealForUpdate.id) }}</span> {{ selectedDealForUpdate.name }}
                </p>

                <div class="mobil">
                    <el-button

                        size="medium"
                        type="success"
                        v-if="
                            permissions.some(
                                (per) =>
                                    per.slug ==
                                    'deals.archivate'
                            )
                        "

                        @click="
                            archivatingDeal(
                                selectedDealForUpdate.id
                            )
                        "
                        >{{ $t("message.archivate_deal") }}
                    </el-button
                    >
                    <el-button

                        size="medium"
                        type="danger"
                        @click="drawerDeleteDeal = true"
                        v-if="
                            permissions.some(
                                (per) =>
                                    per.slug == 'deals.delete'
                            )
                        "
                        >{{ $t("message.delete_deal") }}
                    </el-button>
                    <el-button class="bg-none-style"   size="medium" @click="is_calendar ? closeCalendar() : close()">
                        <!-- {{ $t("message.close") }} -->
                        <i class="fa-solid fa-xmark"></i>
                    </el-button>
                </div>
            </div>

            <div class="app-modal__body p-5 mobil-p5">
                <deal-stages
                    @stageChanged="dealStageChanged"
                    :stage_id="stage_id"
                    :deal_id="selectedDealForUpdate"
                    ref="dealStages"
                ></deal-stages>

                <div class="deal-tab-wrap deal-tab-scroll">
                    <el-tabs v-model="activeName">
                        <el-tab-pane :label="$t('message.overall')" name="first">
                            <el-row :gutter="10">
                                <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
                                    <div class="w-100 el-collapse-khan-deals" >
                                        <el-collapse v-model="activeNames" >

                                            <el-collapse-item :title="$t('message.about_deal')" name="a1" class="mb-3">
                                                <div class="gc-card rounded-sm p-relative">
                                                    <div class="gc-card__body px-3 py-2">
                                                        <div class="u_p_info" @click="drawerUpdateDeal = true">
                                                            <i class="el-icon-edit"> </i> {{ $t("message.update") }}
                                                        </div>

                                                        <div class="text-descr">
                                                            <span
                                                                class="
                                                                    text-descr__label
                                                                    d-block
                                                                "
                                                                >{{
                                                                    $t("message.filial")
                                                                }}</span
                                                            >
                                                            <div
                                                                class="
                                                                    d-flex
                                                                    align-center
                                                                    font-medium
                                                                "
                                                            >
                                                                {{ selectedDealForUpdate.filial ? selectedDealForUpdate.filial.name : "" }}
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div v-if="selectedDealForUpdate.paid_money" class="gc-card__body px-3 py-2">
                                                        <div class="text-descr">
                                                            <span
                                                                class="
                                                                    text-descr__label
                                                                    d-block
                                                                "
                                                                >{{
                                                                    $t(
                                                                        "message.paid_money"
                                                                    )
                                                                }}</span
                                                            >
                                                            <div
                                                                class="
                                                                    d-flex
                                                                    align-center
                                                                    font-medium
                                                                "
                                                            >
                                                                <span>
                                                                    {{ selectedDealForUpdate.paid_money | formatNumber(1) }} {{ currencyCode }}
                                                                </span>
                                                                <span class="ml-5">{{ calculatePaidMoneyAmountInSystemCurrency }}</span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div v-if="dealMoneyAmount" class="gc-card__body px-3 py-2">
                                                        <div class="text-descr">
                                                            <span
                                                                class="
                                                                    text-descr__label
                                                                    d-block
                                                                "
                                                                >{{ $t( "message.money_amount")}}
                                                            </span>
                                                            <div
                                                                class="
                                                                    d-flex
                                                                    align-center
                                                                    font-medium
                                                                "
                                                            >
                                                                <span>
                                                                    {{ dealMoneyAmount }}
                                                                </span>
                                                                <span class="ml-5">{{ calculateMoneyAmountInSystemCurrency }}</span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div v-if="dealProfit" class="gc-card__body px-3 py-2">
                                                        <div class="text-descr">
                                                            <span
                                                                class="
                                                                    text-descr__label
                                                                    d-block
                                                                "
                                                                >{{
                                                                    $t("message.profit")
                                                                }}</span
                                                            >
                                                            <div
                                                                class="
                                                                    d-flex
                                                                    align-center
                                                                    font-medium
                                                                "
                                                            >
                                                                {{ dealProfit }}
                                                                <span class="ml-5">{{ calculateProfitInSystemCurrency }}</span>
                                                            </div>
                                                        </div>
                                                                        <div class="text-descr">
                                                            <span
                                                                class="
                                                                    text-descr__label
                                                                    d-block
                                                                "
                                                                >{{
                                                                    $t("message.profit_user")
                                                                }}</span
                                                            >
                                                            <div
                                                                class="
                                                                    d-flex
                                                                    align-center
                                                                    font-medium
                                                                "
                                                            >
                                                                {{ managerProfit }}
                                                                <span class="ml-5">{{ calculateManagerProfitInSystemCurrency }}</span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="gc-card__body px-3 py-2">
                                                        <div class="text-descr">
                                                            <span
                                                                class="
                                                                    text-descr__label
                                                                    pr-5
                                                                    mr-5
                                                                "
                                                                >{{
                                                                    $t("message.begin_date")
                                                                }}</span
                                                            >
                                                            <span
                                                                class="
                                                                    text-descr__label
                                                                    pl-5
                                                                    ml-3
                                                                "
                                                                >{{
                                                                    $t("message.end_date")
                                                                }}</span
                                                            >
                                                            <div
                                                                class="
                                                                    d-flex
                                                                    align-center
                                                                    font-medium
                                                                "
                                                            >
                                                                <span class="pr-5 mr-5">
                                                                    {{
                                                                        formatDate(
                                                                            selectedDealForUpdate.begin_date
                                                                        )
                                                                    }}
                                                                </span>
                                                                <span class="pl-5 ml-5">
                                                                    {{
                                                                        formatDate(
                                                                            selectedDealForUpdate.end_date
                                                                        )
                                                                    }}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div v-if="selectedDealForUpdate.exhibition" class="gc-card__body px-3 py-2">
                                                        <div class="text-descr">
                                                            <span
                                                                class="
                                                                    text-descr__label
                                                                    d-block
                                                                "
                                                                >{{
                                                                    $t("message.exhibition")
                                                                }}</span
                                                            >
                                                            <div
                                                                class="
                                                                    d-flex
                                                                    align-center
                                                                    font-medium
                                                                "
                                                            >
                                                                {{
                                                                    selectedDealForUpdate.exhibition
                                                                        ? selectedDealForUpdate
                                                                            .exhibition.name
                                                                        : ""
                                                                }}
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div v-if="selectedDealForUpdate.company" class="gc-card__body px-3 py-2">
                                                        <div class="text-descr">
                                                            <span
                                                                class="
                                                                    text-descr__label
                                                                    d-block
                                                                "
                                                                >{{
                                                                    $t("message.company")
                                                                }}</span
                                                            >
                                                            <div
                                                                class="
                                                                    d-flex
                                                                    align-center
                                                                    font-medium
                                                                "
                                                            >
                                                                {{
                                                                    selectedDealForUpdate.company
                                                                        ? selectedDealForUpdate
                                                                            .company.name
                                                                        : ""
                                                                }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="gc-card__body px-3 py-2">
                                                        <div class="text-descr">
                                                            <span
                                                                class="
                                                                    text-descr__label
                                                                    d-block
                                                                "
                                                                >{{
                                                                    $t("message.client")
                                                                }}</span
                                                            >
                                                            <div
                                                                class="
                                                                    d-flex
                                                                    align-center
                                                                    font-medium
                                                                "
                                                            >
                                                                <a
                                                                    class="
                                                                        text-descr__phone
                                                                        f-fill
                                                                        text-reset
                                                                    "
                                                                >
                                                                    {{
                                                                        selectedDealForUpdate.client
                                                                            ? selectedDealForUpdate
                                                                                .client
                                                                                .full_name
                                                                            : ""
                                                                    }}
                                                                </a>
                                                                <i
                                                                    class="
                                                                        el-icon-edit-outline
                                                                    "
                                                                    @click="
                                                                        drawerUpdateClient = true
                                                                    "
                                                                    style="font-size: 1.5em"
                                                                ></i>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div v-if="selectedDealForUpdate.client && selectedDealForUpdate.client.country" class="gc-card__body px-3 py-2">
                                                        <div class="text-descr">
                                                            <span
                                                                class="
                                                                    text-descr__label
                                                                    d-block
                                                                "
                                                                >{{
                                                                    $t("message.country")
                                                                }}</span
                                                            >
                                                            <div
                                                                class="
                                                                    d-flex
                                                                    align-center
                                                                    font-medium
                                                                "
                                                            >
                                                                {{
                                                                    selectedDealForUpdate.client
                                                                        ? selectedDealForUpdate
                                                                            .client
                                                                            .country
                                                                            ? selectedDealForUpdate
                                                                                .client
                                                                                .country
                                                                                .name
                                                                            : ""
                                                                        : ""
                                                                }}
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div v-if="selectedDealForUpdate.phone" class="gc-card__body px-3 py-2">
                                                        <div class="text-descr">
                                                            <span
                                                                class="
                                                                    text-descr__label
                                                                    d-block
                                                                "
                                                                >{{
                                                                    $t("message.phone")
                                                                }}</span
                                                            >
                                                            <div
                                                                class="
                                                                    d-flex
                                                                    align-center
                                                                    font-medium
                                                                "
                                                            >
                                                                <a
                                                                    :href="'tell:'"
                                                                    class="
                                                                        text-descr__phone
                                                                        f-fill
                                                                        text-reset
                                                                    "
                                                                >
                                                                    {{
                                                                        selectedDealForUpdate.phone
                                                                    }}
                                                                </a>
                                                                <span
                                                                    class="
                                                                        phone-detect
                                                                        pl-2
                                                                    "
                                                                >
                                                                    {{ $t("message.working") }}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div v-if="selectedDealForUpdate.email" class="gc-card__body px-3 py-2">
                                                        <div class="text-descr mb-3">
                                                            <span
                                                                class="
                                                                    text-descr__label
                                                                    d-block
                                                                "
                                                                >{{
                                                                    $t("message.email")
                                                                }}</span
                                                            >
                                                            <div
                                                                class="
                                                                    d-flex
                                                                    align-center
                                                                    font-medium
                                                                "
                                                            >
                                                                {{
                                                                    selectedDealForUpdate.email
                                                                }}
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div v-if="selectedDealForUpdate.shipmentTerm" class="gc-card__body px-3 py-2">
                                                        <div class="text-descr mb-3">
                                                            <span
                                                                class="
                                                                    text-descr__label
                                                                    d-block
                                                                "
                                                                >{{
                                                                    $t(
                                                                        "message.shipmentTerm"
                                                                    )
                                                                }}</span
                                                            >
                                                            <div
                                                                class="
                                                                    d-flex
                                                                    align-center
                                                                    font-medium
                                                                "
                                                            >
                                                                {{
                                                                    selectedDealForUpdate.shipmentTerm
                                                                        ? selectedDealForUpdate
                                                                            .shipmentTerm
                                                                            .name
                                                                        : ""
                                                                }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </el-collapse-item>

                                            <el-collapse-item :title="$t('message.dealCondition')" class="mb-3" name="a2"
                                                v-if="
                                                        selectedDealForUpdate.deal_payments &&
                                                        selectedDealForUpdate.deal_payments
                                                            .length > 0
                                                ">
                                                <div class="gc-card rounded-sm"

                                                >
                                                    <div class="gc-card__body px-3 py-4">
                                                        <div
                                                            class="gc-card__body px-3 py-2"
                                                        >
                                                            <div class="text-descr mb-3">
                                                                <el-progress
                                                                    style="len"
                                                                    class="mt-3"
                                                                    :stroke-width="13"
                                                                    :percentage="
                                                                        totalPaymentPercentage
                                                                    "
                                                                    :color="customColors"
                                                                    v-if="
                                                                        selectedDealForUpdate.deal_payments &&
                                                                        selectedDealForUpdate
                                                                            .deal_payments
                                                                            .length > 0
                                                                    "
                                                                ></el-progress>
                                                            </div>
                                                        </div>

                                                        <hr
                                                            v-if="
                                                                selectedDealForUpdate.deal_payments &&
                                                                selectedDealForUpdate
                                                                    .deal_payments.length >
                                                                    0
                                                            "
                                                        />

                                                        <div
                                                            v-for="payment in selectedDealForUpdate.deal_payments"
                                                            :key="payment.id"
                                                        >
                                                            <div class="gc-card__body mb-4">
                                                                <span
                                                                    class="
                                                                        text-descr__label
                                                                        d-block
                                                                    "
                                                                    >{{
                                                                        $t(
                                                                            "message.dealCondition"
                                                                        )
                                                                    }}</span
                                                                >
                                                                <div
                                                                    class="
                                                                        d-flex
                                                                        align-center
                                                                        font-medium
                                                                    "
                                                                >
                                                                    {{
                                                                        payment.payment_condition
                                                                            ? payment
                                                                                .payment_condition
                                                                                .name
                                                                            : ""
                                                                    }}
                                                                </div>
                                                            </div>

                                                            <div class="text-descr d-flex">
                                                                <div class="mmq">
                                                                    <span
                                                                        class="
                                                                            text-descr__label
                                                                            pr-5
                                                                            mr-5
                                                                        "
                                                                    >
                                                                        {{
                                                                            $t(
                                                                                "message.payment_in_money"
                                                                            )
                                                                        }}
                                                                    </span>
                                                                    <span>
                                                                        {{
                                                                            ((payment.percentage *
                                                                                selectedDealForUpdate.money_amount) /
                                                                                100)
                                                                                | formatNumber(
                                                                                    1
                                                                                )
                                                                        }}
                                                                        {{
                                                                            selectedDealForUpdate.currency
                                                                                ? selectedDealForUpdate
                                                                                    .currency
                                                                                    .code
                                                                                : ""
                                                                        }}
                                                                    </span>
                                                                </div>
                                                                <div class="mmq">
                                                                    <span
                                                                        class="
                                                                            text-descr__label
                                                                        "
                                                                    >
                                                                        {{
                                                                            $t(
                                                                                "message.payment_in_percents"
                                                                            )
                                                                        }}
                                                                    </span>
                                                                    <span>
                                                                        {{
                                                                            payment.percentage
                                                                        }}
                                                                        %
                                                                    </span>
                                                                </div>
                                                            </div>

                                                            <div class="text-descr d-flex">
                                                                <div class="mmq">
                                                                    <span
                                                                        class="
                                                                            text-descr__label
                                                                            pr-5
                                                                            mr-5
                                                                        "
                                                                    >
                                                                        {{ $t("message.payment_start_date") }}
                                                                    </span>
                                                                    <span>
                                                                        {{
                                                                            formatDate(
                                                                                payment.begin_date
                                                                            )
                                                                        }}
                                                                    </span>
                                                                </div>
                                                                <div class="mmq">
                                                                    <span
                                                                        class="
                                                                            text-descr__label
                                                                        "
                                                                    >
                                                                        {{ $t("message.payment_end_date") }}
                                                                    </span>
                                                                    <span>
                                                                        {{
                                                                            formatDate(
                                                                                payment.end_date
                                                                            )
                                                                        }}
                                                                    </span>
                                                                </div>
                                                            </div>

                                                            <div class="gc-card__body mb-4">
                                                                <div
                                                                    class="text-descr mb-3"
                                                                >
                                                                    <span
                                                                        class="
                                                                            text-descr__label
                                                                            d-block
                                                                        "
                                                                    >
                                                                        {{
                                                                            $t(
                                                                                "message.comment"
                                                                            )
                                                                        }}
                                                                    </span>
                                                                    <div
                                                                        class="
                                                                            d-flex
                                                                            align-center
                                                                            font-medium
                                                                        "
                                                                    >
                                                                        {{
                                                                            payment.comment
                                                                        }}
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <hr />
                                                        </div>
                                                    </div>
                                                </div>
                                            </el-collapse-item>

                                            <el-collapse-item :title="$t('message.additional')" name="a3" class="mb-3">
                                                <div class="gc-card rounded-sm">
                                                    <div class="gc-card__body px-3 py-4">
                                                        <div v-if="selectedDealForUpdate.deal_type" class="text-descr">
                                                            <span
                                                                class="
                                                                    text-descr__label
                                                                    d-block
                                                                "
                                                            >
                                                                {{
                                                                    $t("message.deal_type")
                                                                }}
                                                            </span>
                                                            <div
                                                                class="
                                                                    d-flex
                                                                    align-center
                                                                    font-medium
                                                                "
                                                            >
                                                                <span
                                                                    class="
                                                                        text-descr__text
                                                                        f-fill
                                                                        text-reset
                                                                    "
                                                                >
                                                                    {{
                                                                        selectedDealForUpdate.deal_type
                                                                            ? selectedDealForUpdate
                                                                                .deal_type
                                                                                .name
                                                                            : ""
                                                                    }}
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div v-if="selectedDealForUpdate.source" class="text-descr">
                                                            <span
                                                                class="
                                                                    text-descr__label
                                                                    d-block
                                                                "
                                                            >
                                                                {{ $t("message.source") }}
                                                            </span>
                                                            <div
                                                                class="
                                                                    d-flex
                                                                    align-center
                                                                    font-medium
                                                                "
                                                            >
                                                                <span
                                                                    class="
                                                                        text-descr__text
                                                                        f-fill
                                                                        text-reset
                                                                    "
                                                                    >{{
                                                                        selectedDealForUpdate.source
                                                                            ? selectedDealForUpdate
                                                                                .source
                                                                                .name
                                                                            : ""
                                                                    }}</span
                                                                >
                                                            </div>
                                                        </div>

                                                        <div v-if="selectedDealForUpdate.reception_address" class="text-descr">
                                                            <span
                                                                class="
                                                                    text-descr__label
                                                                    d-block
                                                                "
                                                            >
                                                                {{ $t("message.reception_address") }}
                                                            </span>
                                                            <div
                                                                class="
                                                                    d-flex
                                                                    align-center
                                                                    font-medium
                                                                "
                                                            >
                                                                <span
                                                                    class="
                                                                        text-descr__text
                                                                        f-fill
                                                                        text-reset
                                                                    "
                                                                    >{{
                                                                        selectedDealForUpdate.reception_address
                                                                    }}</span
                                                                >
                                                            </div>
                                                        </div>

                                                        <div v-if="selectedDealForUpdate.comment" class="text-descr">
                                                            <span
                                                                class="
                                                                    text-descr__label
                                                                    d-block
                                                                "
                                                            >
                                                                {{ $t("message.comment") }}
                                                            </span>
                                                            <div
                                                                class="
                                                                    d-flex
                                                                    align-center
                                                                    font-medium
                                                                "
                                                            >
                                                                <span
                                                                    class="
                                                                        text-descr__text
                                                                        f-fill
                                                                        text-reset
                                                                    "
                                                                    >{{
                                                                        selectedDealForUpdate.comment
                                                                    }}</span
                                                                >
                                                            </div>
                                                        </div>
                                                        <div v-if="selectedDealForUpdate.paymentFiles && selectedDealForUpdate.paymentFiles.length > 0" class="text-descr">
                                                            <span
                                                                class="
                                                                    text-descr__label
                                                                    d-block
                                                                "
                                                            >
                                                                {{ $t("message.files_of_deal_conditions") }}
                                                            </span>
                                                            <div
                                                                v-for="file in selectedDealForUpdate.paymentFiles"
                                                                :key="file.name"
                                                                @click="downloadFile( selectedDealForUpdate.id, file.name, 'dealPaymentFiles')"
                                                                class="
                                                                    d-flex
                                                                    align-center
                                                                    mir
                                                                    font-medium
                                                                    pb-2
                                                                "
                                                                
                                                            >
                                                                <span
                                                                    class="
                                                                        text-descr__text
                                                                        text-reset
                                                                    "
                                                                >
                                                                    <i
                                                                        class="
                                                                            el-icon-document-checked
                                                                        "
                                                                    ></i>
                                                                    {{ file ? file.name : "" }}
                                                                </span>
                                                                <span
                                                                    class="
                                                                        text-descr__text
                                                                        text-reset
                                                                    "
                                                                >
                                                                    <i
                                                                        class="
                                                                            el-icon-download
                                                                        "
                                                                    ></i>
                                                                </span>
                                                            </div>
                                                        </div>

                                                        <div v-if="selectedDealForUpdate.files && selectedDealForUpdate.files.length > 0" class="text-descr">
                                                            <span
                                                                class="
                                                                    text-descr__label
                                                                    d-block
                                                                "
                                                            >
                                                                {{ $t("message.files") }}
                                                            </span>
                                                            <div
                                                                v-for="file in selectedDealForUpdate.files"
                                                                :key="file.name"
                                                                @click="downloadFile(selectedDealForUpdate.id, file.name, 'dealFiles')"
                                                                class="
                                                                    d-flex
                                                                    align-center
                                                                    mir
                                                                    font-medium
                                                                    pb-2
                                                                "
                                                            >
                                                                <span
                                                                    class="
                                                                        text-descr__text
                                                                        text-reset
                                                                    "
                                                                >
                                                                    <i
                                                                        class="
                                                                            el-icon-document-checked
                                                                        "
                                                                    ></i>
                                                                    {{ file ? file.name : "" }}
                                                                </span>
                                                                <span
                                                                    class="
                                                                        text-descr__text
                                                                        text-reset
                                                                    "
                                                                >
                                                                    <i
                                                                        class="
                                                                            el-icon-download
                                                                        "
                                                                    ></i>
                                                                </span>
                                                            </div>
                                                        </div>

																		   <div v-if="selectedDealForUpdate.clientFormFiles && selectedDealForUpdate.clientFormFiles.length > 0" class="text-descr">
                                                            <span
                                                                class="
                                                                    text-descr__label
                                                                    d-block
                                                                "
                                                            >
                                                                {{ $t("message.files_of_client_form") }}
                                                            </span>
                                                            <div
                                                                v-for="file in selectedDealForUpdate.clientFormFiles"
                                                                :key="file.name"
                                                                @click="downloadFile(selectedDealForUpdate.client_form_id, file.name, 'clientFormFiles')"
                                                                class="
                                                                    d-flex
                                                                    align-center
                                                                    mir
                                                                    font-medium
                                                                    pb-2
                                                                "
                                                            >
                                                                <span
                                                                    class="
                                                                        text-descr__text
                                                                        text-reset
                                                                    "
                                                                >
                                                                    <i
                                                                        class="
                                                                            el-icon-document-checked
                                                                        "
                                                                    ></i>
                                                                    {{ file ? file.name : "" }}
                                                                </span>
                                                                <span
                                                                    class="
                                                                        text-descr__text
                                                                        text-reset
                                                                    "
                                                                >
                                                                    <i
                                                                        class="
                                                                            el-icon-download
                                                                        "
                                                                    ></i>
                                                                </span>
                                                            </div>
                                                        </div>

                                                        <div class="text-descr">
                                                            <span
                                                                class="
                                                                    text-descr__label
                                                                    d-block
                                                                "
                                                            >
                                                                {{
                                                                    $t(
                                                                        "message.responsible"
                                                                    )
                                                                }}
                                                            </span>
                                                            <div
                                                                class="
                                                                    responsible-block
                                                                    d-flex
                                                                    align-center
                                                                    font-medium
                                                                    p-2
                                                                    rounded-sm
                                                                "
                                                            >
                                                                <div
                                                                    class="
                                                                        gc-small-block
                                                                        rounded-circle
                                                                        hidden
                                                                        mr-3
                                                                    "
                                                                    style="border: 1px solid gray;"
                                                                >
                                                                    <img
                                                                        :src="
                                                                            selectedDealForUpdate.user
                                                                                ? selectedDealForUpdate
                                                                                    .user
                                                                                    .avatar
                                                                                    ? selectedDealForUpdate
                                                                                        .user
                                                                                        .avatar
                                                                                    : '/img/default-user-icon.png'
                                                                                : '/img/default-user-icon.png'
                                                                        "
                                                                        alt="Default Avatar"
                                                                        class="d-block"
                                                                    />
                                                                </div>
                                                                <div class="f-fill">
                                                                    <span
                                                                        class="
                                                                            text-descr__text
                                                                            f-fill
                                                                        "
                                                                    >
                                                                        {{
                                                                            selectedDealForUpdate.user
                                                                                ? selectedDealForUpdate
                                                                                    .user
                                                                                    .name
                                                                                : ""
                                                                        }}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div v-if="selectedDealForUpdate.spectators && selectedDealForUpdate.spectators.length > 0"
                                                            class="text-descr">
                                                            <span
                                                                class="
                                                                    text-descr__label
                                                                    d-block
                                                                "
                                                            >
                                                                {{
                                                                    $t("message.spectators")
                                                                }}
                                                            </span>
                                                            <div
                                                                v-for="spectator in selectedDealForUpdate.spectators"
                                                                :key="spectator.id"
                                                                class="
                                                                    responsible-block
                                                                    d-flex
                                                                    align-center
                                                                    font-medium
                                                                    p-2
                                                                    rounded-sm
                                                                "
                                                            >
                                                                <div
                                                                    class="
                                                                        gc-small-block
                                                                        rounded-circle
                                                                        hidden
                                                                        mr-3
                                                                    "
                                                                    style="
                                                                        border: 1px solid
                                                                            gray;
                                                                    "
                                                                >
                                                                    <img
                                                                        :src="
                                                                            spectator
                                                                                ? spectator.avatar
                                                                                    ? spectator.avatar
                                                                                    : '/img/default-user-icon.png'
                                                                                : '/img/default-user-icon.png'
                                                                        "
                                                                        alt="Default Avatar"
                                                                        class="d-block"
                                                                    />
                                                                </div>
                                                                <div class="f-fill mirdel">
                                                                    <span
                                                                        class="
                                                                            text-descr__text
                                                                            f-fill
                                                                        "
                                                                    >
                                                                        {{
                                                                            spectator
                                                                                ? spectator.name
                                                                                : ""
                                                                        }}
                                                                    </span>
                                                                    <span
                                                                        class="
                                                                            text-descr__text
                                                                            text-reset
                                                                        "
                                                                        @click="
                                                                            deletingSpectator(
                                                                                spectator
                                                                                    ? spectator.id
                                                                                    : ''
                                                                            )
                                                                        "
                                                                    >
                                                                        <i
                                                                            class="
                                                                                el-icon-delete
                                                                            "
                                                                            style="
                                                                                color: red;
                                                                            "
                                                                        ></i>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </el-collapse-item>

														   <el-collapse-item :title="$t('message.contact_person')" name="a4" class="mb-3" v-if="client_contact_peoples.length > 0">
                                                <div class="gc-card rounded-sm">
                                                   <div class="gc-card__body px-3 py-4">
                                                      <el-table :data="client_contact_peoples" style="width: 100%">
																			<el-table-column prop="full_name" :label="$t('message.first_name')">
																			</el-table-column>
																			<el-table-column prop="phone" :label="$t('message.phone')">
																			</el-table-column>
																		</el-table>
                                                   </div>
                                                </div>
                                            </el-collapse-item>
                                        </el-collapse>
                                    </div>
                                </el-col>
                                <el-col :xs="24" :sm="24" :md="24" :lg="16" :xl="16">
                                    <div class="w-100">
													<deal-correspondences
														v-can="'dealCorrespondences.index'"
														ref="dealCorrespondence"
														@listChanged="listChanged"
														:deal_id="selectedDealForUpdate.id"
														:deal_email="selectedDealForUpdate.email"
													>
													</deal-correspondences>
                                		</div>
                                </el-col>
                            </el-row>
                        </el-tab-pane>

                        <el-tab-pane :label="$t('message.products')" name="second">
                            <deal-products
                                v-can="'dealProducts.index'"
                                ref="dealProducts"
                                @listChanged="listChanged"
                                @updateProductTotal="updateProductTotal"
                                @dealOffersCreate="dealOffersCreate"
                                :filial_id="selectedDealForUpdate.filial ? selectedDealForUpdate.filial.id : selectedDealForUpdate.filial_id"
                                :deal_id="selectedDealForUpdate.id"
                                :product_calculation_type_id="selectedDealForUpdate.product_calculation_type_id"
                                :deal_calculate_money="selectedDealForUpdate.calculate_money"
										  :min_profit="selectedDealForUpdate.min_profit"
                            ></deal-products>
                        </el-tab-pane>

                        <el-tab-pane :label="$t('message.offer')" name="third">
                            <deal-offers
                                v-can="'dealOffers.index'"
                                @listChanged="listChanged"
                                :deal_id="selectedDealForUpdate.id"
                            ></deal-offers>
                        </el-tab-pane>

                        <el-tab-pane :label="$t('message.tasks')" name="task">
                            <deal-tasks
                                :deal_id="selectedDealForUpdate.id"
                            ></deal-tasks>
                            <div class="mt-5">
                                <el-row class="mt-5">
                                    <el-button
                                        @click="
                                            addTaskToDeal(
                                                selectedDealForUpdate.id
                                            )
                                        "
                                        icon="el-icon-circle-plus-outline"
                                         type="info"
                                        >{{ $t('message.add_task') }}</el-button
                                    >
                                </el-row>
                            </div>
                        </el-tab-pane>

                        <el-tab-pane :label="$t('message.notifications_nn')" name="notification">
                            <el-row :gutter="20">
                                <el-col :span="24">
                                    <el-table :data="notifications" border style="width: 100%" v-loading="loadingNotifications">
                                        <el-table-column type="index" width="50">
                                        </el-table-column>
                                        <el-table-column prop="type.name" :label="$t('message.notificationType')">
                                        </el-table-column>
                                        <el-table-column prop="user.name" :label="$t('message.user')">
                                        </el-table-column>
                                        <el-table-column prop="text" :label="$t('message.text')" width="400">
                                        </el-table-column>
                                        <el-table-column :label="$t('message.users')">
                                            <template slot-scope="item">
                                                <el-button type="text" @click="showItems(item.row.id)">{{ $t('message.view') }}</el-button>
                                            </template>
                                        </el-table-column>
                                        <el-table-column prop="activity_time" :label="$t('message.activity_time')">
                                        </el-table-column>
                                        <el-table-column prop="created_at" :label="$t('message.created_at')">
                                        </el-table-column>
                                        <el-table-column :label="$t('message.settings')">
                                            <template slot-scope="item">
                                                <crm-settings
                                                :name="$options.name"
                                                :model="item.row"
                                                :actions="actions"
                                                :permissionShow="'systeamNotification.update'"
                                                :permissionDestroy="'systeamNotification.delete'"
                                                @edit="edit"
                                                @delete="destroy"
                                            ></crm-settings>
                                            </template>
                                        </el-table-column>
                                    </el-table>
                                        <div class="my___pagination">
                                            <crm-pagination
                                                    @c-change="updatePagination"
                                                    :class="mode ? 'pagination__day' : 'pagination__night'"
                                                    :pagination="pagination"
                                            ></crm-pagination>
                                            <!-- <Pagination /> -->
                                        </div>
                                </el-col>
                            </el-row>
                        </el-tab-pane>
                    </el-tabs>
                </div>
                <div class="change-data-overlay p-absolute w-100 h-100"></div>
            </div>
        </div>


    <!-- Modals -->
        <el-dialog
            :class="mode ? 'l-modal-style' : 'd-modal-style'"
            class="KP_modal"
            :title="$t('message.delete')"
            :visible.sync="drawerDeleteDeal"
            :append-to-body="true"
            width="30%"
            center
            ref="drawerDeleteDeal"
            @opened="drawerOpened('deleteDealComponent')"
            @closed="drawerClosed('deleteDealComponent')"
        >
            <delete-deal
                ref="dealOfferComponent"
                :deal_id="selectedDealForUpdate ? selectedDealForUpdate.id : ''"
                @deleteDealClose="deleteDealClose"
                drawer="drawerDealOfferCreate"
            >
            </delete-deal>
        </el-dialog>

        <el-dialog
            :class="mode ? 'l-modal-style' : 'd-modal-style'"
            class="KP_modal el-dialog-w-100"
            :title="$t('message.kp')"
            :visible.sync="centerDialogVisible"
            :append-to-body="true"
            width="30%"
            center
            ref="drawerDealOfferCreate"
            @opened="drawerOpened('dealOfferComponent')"
            @closed="drawerClosed('dealOfferComponent')"
        >
            <kp-deal-offer
                ref="dealOfferComponent"
                :filial_id="selectedDealForUpdate.filial ? selectedDealForUpdate.filial.id : selectedDealForUpdate.filial_id"
                @dealOffersClose="dealOffersClose"
                @listChanged="listChanged"
                drawer="drawerDealOfferCreate"
            >
            </kp-deal-offer>
        </el-dialog>

        <el-drawer
            :class="mode ? 'l-modal-style' : 'd-modal-style'"
            :append-to-body="true"
            size="80%"
            :with-header="false"
            :visible.sync="drawerCreateDeal"
            custom-class="modal-style-new-v"
            ref="drawerDealCreate"
            @opened="drawerOpened('drawerDealCreateChild')"
            @closed="drawerClosed('drawerDealCreateChild')"
        >
            <crm-create-deal
                :board_id="board_id"
                ref="drawerDealCreateChild"
                drawer="drawerDealCreate"
            ></crm-create-deal>
        </el-drawer>

        <el-drawer
            :class="mode ? 'l-modal-style' : 'd-modal-style'"
            :append-to-body="true"
            size="80%"
            :with-header="false"
            :visible.sync="drawerUpdateDeal"
            custom-class="modal-style-new-v"
            ref="drawerDealUpdate"
            @opened="drawerOpened('drawerDealUpdateChild')"
            @closed="drawerClosed('drawerDealUpdateChild')"
        >
            <crm-update-deal
                :board_id="board_id"
                :selectedItem="selectedDealForUpdate"
                ref="drawerDealUpdateChild"
                drawer="drawerDealUpdate"
            ></crm-update-deal>
        </el-drawer>

        <div class="app-modal app-modal__full">
            <el-drawer
                :class="mode ? 'l-modal-style' : 'd-modal-style'"
                :append-to-body="true"
                :with-header="false"
                :visible.sync="drawerUpdateClient"
                size="70%"
                ref="drawerUpdateClient"
                class="body_scroll_70 bg-se"
                @opened="drawerOpened('drawerUpdateClientChild')"
                @closed="drawerClosed('drawerUpdateClientChild')"
            >
                <crm-update-client
                    :selectedItem="
                        selectedDealForUpdate.client
                            ? selectedDealForUpdate.client
                            : null
                    "
                    ref="drawerUpdateClientChild"
                    drawer="drawerUpdateClient"
                ></crm-update-client>
            </el-drawer>

            <el-dialog
                :class="mode ? 'l-modal-style' : 'd-modal-style'"
                :title="$t('message.update_payment')"
                :visible.sync="paymentDrawer"
                width="30%"
                :append-to-body="true"
                class="add-participants"
                ref="drawerPayments"
                @opened="drawerOpened('drawerPaymentsChild')"
                @closed="drawerClosed('drawerPaymentsChild')"
            >
                <CrmUpdatePayment
                    ref="drawerPaymentsChild"
                    drawer="drawerPayments"
                >
                </CrmUpdatePayment>
            </el-dialog>
            <!-- end AddingTask -->

			<!-- Notification update dialog -->
            <el-dialog
                class="el-dialog-w-100"
				:title="$t('message.notifications_nn')"
				:visible.sync="dialogVisible"
				width="30%"
				:append-to-body="true"
				@open="openDialog"
				@closed="closedDialog"
		  	>
				<el-form ref="notification_form" :model="notification_form" :rules="rules" label-position="top">
					<el-form-item :label="$t('message.notificationTypes')" prop="type_id">
						<select-notification-types
							v-model="notification_form.type_id"
							:id="notification_form.type_id"
						></select-notification-types>
					</el-form-item>
					<el-form-item :label="$t('message.text')" prop="text">
						<el-input type="textarea" v-model="notification_form.text"></el-input>
					</el-form-item>
					<el-form-item :label="$t('message.activity_time')">
						<el-col :span="11">
							<el-date-picker
								type="datetime"
								format="yyyy-MM-dd HH:mm"
								value-format="yyyy-MM-dd HH:mm"
								v-model="notification_form.activity_time"
								:placeholder="$t('message.activity_time')"
								style="width: 100%;"

							>
							</el-date-picker>
						</el-col>
					</el-form-item>
					<el-form-item :label="$t('message.users')" prop="user_ids">
						<el-select
							v-model="notification_form.user_ids"
							multiple
							collapse-tags
							filterable
							:placeholder="$t('message.users')"
							class="w-100"
						>
							<el-option
								v-for="item in usersList"
								:key="item.id"
								:label="item.name"
								:value="item.id">
							</el-option>
						</el-select>
					</el-form-item>
					<el-row>
						<el-button @click="saveSysteamNotification" :loading="loadingButton" type="success">{{ $t('message.save') }}</el-button>
						<el-button @click="dialogVisible = false" type="danger">{{ $t('message.close') }}</el-button>
					</el-row>
				</el-form>
     		</el-dialog>

			<el-dialog :title="$t('message.users')" :visible.sync="dialogTableVisible" @closed="closedDialogUsers" top="5vh" :append-to-body="true">
				<el-table :data="notes_users" height="500"  style="width: 100%" v-loading="loadingNotificationUser">
					<el-table-column property="id" label="#" width="70"></el-table-column>
					<el-table-column :label="$t('message.users')" width="350">
						<template slot-scope="item">
							<!-- {{item.row.user ? item.row.user.surname : ''}} -->
							{{item.row.user ? item.row.user.name : ''}}
						</template>
					</el-table-column>
					<el-table-column :label="$t('message.review')">
						<template slot-scope="item">
							{{ item.row.review ? $t('message.reviewed') : $t('message.not_reviewed') }}
						</template>
					</el-table-column>
					<el-table-column property="reviewed_date" :label="$t('message.reviewed_date')">
					</el-table-column>
				</el-table>
			</el-dialog>
        </div>

        <div class="app-modal app-modal__full">
            <el-drawer
                :class="mode ? 'l-modal-style' : 'd-modal-style'"
                class="bg-se"
                :visible.sync="drawerTaskCreate"
                :with-header="false"
                ref="drawerTaskCreate"
                @opened="drawerOpened('drawerTaskCreateChild')"
                @closed="drawerClosed('drawerTaskCreateChild')"
                :append-to-body="true"
                size="80%"
            >
                <CreateTask
                    :selected_deal="selectedDealForUpdate"
                    ref="drawerTaskCreateChild"
                    drawer="drawerTaskCreate"
                />
            </el-drawer>
        </div>
    </div>
</template>

<script>
import DealOffers from "./dealOffer/deal-offers";
import DealTasks from "./dealTask/deal-tasks";
import DealProducts from "./dealProduct/deal-products";
import KpDealOffer from "./dealProduct/kp-deal-offer";
import DealCorrespondences from "./dealCorrespondence/deal-correspondences.vue";
import CrmCreateDeal from "./deal/crm-create-deal";
import CrmUpdateDeal from "./deal/crm-update-deal";
import DealStages from "./dealStage/deal-stages";
import DeleteDeal from "./deleteDeal/delete-deal";
import CrmUpdateClient from "@/views/clients/components/crm-update";
import CrmUpdatePayment from "./payments/crm-update-payment";
import SelectNotificationTypes from '@/components/inventory/select-notification-types';
import { formatMoney } from "@/filters/index";
import { formatNumber } from "@/filters/index";

import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";
import { formatDate } from '@fullcalendar/core';

export default {
    name: 'add-modal',
    mixins: [drawer, form],
    components: {
        DealTasks,
        DealOffers,
        DealStages,
        DealProducts,
        CrmCreateDeal,
        CrmUpdateDeal,
        DealCorrespondences,
        KpDealOffer,
        DeleteDeal,
        CrmUpdateClient,
        CreateTask: () => import('@/views/tasks/components/add-modal'),
        CrmUpdatePayment,
		  SelectNotificationTypes
    },
    props: {
        board_id: {
            default: null,
        },
        selectedDeal: {
            default: null,
            type: Object,
        },
        dealCreate: {
            default: null,
        },
		  is_calendar: {
			default: false
		  },
		  model_id: {
			default: null
		  }
    },
    data() {
        return {
            activeNames: ['a1'],
            form: {},
            stage_id: null,
            loadingData: false,
            drawerUpdateClient: false,
            drawerCreateDeal: false,
            drawerUpdateDeal: false,
            drawerDeleteDeal: false,
				centerDialogVisible: false,
            activeName: "first",
            reloadList: false,
            selectedDealForUpdate: {},
            selectedUsersList: [],
            selectedUsersContainer: false,
            drawerTaskCreate: false,
            drawerTaskCreateChild: false,
            paymentDrawer: false,
            customColors: [
               { color: "#f56c6c", percentage: 20 },
               { color: "#e6a23c", percentage: 40 },
               { color: "#6f7ad3", percentage: 60 },
               { color: "#1989fa", percentage: 80 },
               { color: "#5cb87a", percentage: 100 },
            ],
				notification_form: {
					type_id: null,
					user_ids: [],
					text: '',
					activity_time: '',
					notificationable_type: "deals",
					notificationable_id: null
				},
				dialogVisible: false,
				usersList: [],
				loadingNotifications: false,
				loadingNotificationUser: false,
				dialogTableVisible: false,
				notes_users: []
        };
    },
    computed: {
        ...mapGetters({
            permissions: "auth/permissions",
            getNewDeal: "deals/newForm",
            dealModel: "deals/model",
            stages: "dealStages/list",
            mode: "MODE",
            notifications: 'systeamNotification/list',
            rules: 'systeamNotification/rules',
            users: 'users/inventory',
            pagination: "systeamNotification/pagination",
        }),
		   client_contact_peoples(){
				if (this.selectedDealForUpdate.client && this.selectedDealForUpdate.client.client_contact_peoples){
					return this.selectedDealForUpdate.client.client_contact_peoples;
				}
				return [];
		  },
        currencyCode(){
            return this.selectedDealForUpdate.currency ? this.selectedDealForUpdate.currency.code : "";
        },
        dealMoneyAmount(){
            if(this.selectedDealForUpdate.calculate_money === 'deal'){
                return formatNumber(this.selectedDealForUpdate.money_amount, 1) + ` ${this.currencyCode}`;
            }else{
                return formatNumber(this.selectedDealForUpdate.product_total, 1) + ` ${this.currencyCode}`
            }
        },
        calculateMoneyAmountInSystemCurrency(){
            if(this.selectedDealForUpdate.currency_rate && this.selectedDealForUpdate.calculate_money === 'deal'){
                return formatMoney((parseFloat(this.selectedDealForUpdate.currency_rate) * parseFloat(this.selectedDealForUpdate.money_amount)), 2);
            }else if (this.selectedDealForUpdate.currency_rate){
                return formatMoney(parseFloat(this.selectedDealForUpdate.product_total) * parseFloat(this.selectedDealForUpdate.currency_rate), 2);
            }else{
                return '';
            }
        },
        calculatePaidMoneyAmountInSystemCurrency(){
            if(this.selectedDealForUpdate.currency_rate && this.selectedDealForUpdate.currency_rate > 0){
               return formatMoney((parseFloat(this.selectedDealForUpdate.currency_rate) * parseFloat(this.selectedDealForUpdate.paid_money)), 2);
            }
            return '';
        },
        dealProfit(){
            if(this.selectedDealForUpdate.calculate_money === 'deal'){
                return formatNumber((parseFloat(this.selectedDealForUpdate.money_amount) * parseFloat(this.selectedDealForUpdate.profit)/100), 1) + ` ${this.currencyCode}`;
            }else if (this.selectedDealForUpdate.currency_rate){
                return formatNumber((parseFloat(this.selectedDealForUpdate.product_profit)/parseFloat(this.selectedDealForUpdate.currency_rate)), 1) + ` ${this.currencyCode}`
            }else{
                return '';
            }
        },
		   managerProfit(){
         	return formatNumber(parseFloat(this.selectedDealForUpdate.manager_profit), 1) + ` ${this.currencyCode}`;
        },
        calculateProfitInSystemCurrency(){
            if(this.selectedDealForUpdate.currency_rate){
                if(this.selectedDealForUpdate.calculate_money === 'deal'){
                    return formatMoney((parseFloat(this.selectedDealForUpdate.currency_rate) * parseFloat(this.selectedDealForUpdate.money_amount) * parseFloat(this.selectedDealForUpdate.profit) / 100), 2);
                }else{
                    return formatMoney(parseFloat(this.selectedDealForUpdate.product_profit), 2)
                }
            }else{
                return '';
            }
        },
		   calculateManagerProfitInSystemCurrency(){
         	return formatMoney(parseFloat(this.selectedDealForUpdate.manager_profit), 2);
        },
        totalPaymentPercentage() {
            let percentage = 0;
            if (
                this.selectedDealForUpdate.deal_payments &&
                this.selectedDealForUpdate.deal_payments.length > 0
            ) {
                percentage = this.selectedDealForUpdate.deal_payments
                    .map((item) => item.percentage)
                    .reduce((prev, next) => prev + next);
            }
            return Math.round(percentage);
        },
		  actions: function () {
            return ["edit", "delete"];
        },
    },
	 watch: {
		'pagination.page': {
          handler: async function(newVal, oldVal) {
              if (newVal != oldVal && _.isFunction(this.updateNotifications)) {
					if (!this.loadingNotifications) {
						this.loadingNotifications = true;
 						this.updateNotifications({notificationable_type: 'deals'}).then(() => {this.loadingNotifications = false;});
					}
              }
          },
          deep: true,
          immediate: true,
      },
      'pagination.per_page': {
          handler: async function(newVal, oldVal) {
              if (newVal != oldVal && _.isFunction(this.updateNotifications)) {
                 if (!this.loadingNotifications) {
						this.loadingNotifications = true;
 						this.updateNotifications({notificationable_type: 'deals'}).then(() => {this.loadingNotifications = false;});
						}
              }
          },
          deep: true,
          immediate: true,
      },
	 },
    methods: {
        ...mapActions({
            updateList: "deals/index",
            setPagination: "deals/setPagination",
            updateSort: "deals/updateSort",
            updateFilter: "deals/updateFilter",
            updateColumn: "deals/updateColumn",
            updatePagination: "deals/updatePagination",
            show: "deals/show",
            refreshData: "deals/refreshData",
            showLastDeal: "deals/showLast",
            dealOffers: "dealOffers/index",
            emptyDealCorrespondences: "dealCorrespondences/emptyList",
            empty: "deals/empty",
            downloadDealFile: "deals/downloadDealFile",
            archivateDeal: "deals/archivateDeal",
            delete: "deals/destroy",
            deleteSpectator: "deals/deleteSpectator",
            updateNotifications: 'systeamNotification/index',
            notificationShow: 'systeamNotification/show',
            usersInventory: 'users/inventoryForFilter',
            saveNotification: 'systeamNotification/update',
            deleteNotification: 'systeamNotification/destroy',
            getNotificationUsers: "systeamNotification/getNotificationUsers",
            updatePagination: "systeamNotification/updatePagination",
        }),
        closeAddModel2() {
            this.$emit('addModalEdit', true)
        },
        updateProductTotal(total){
            this.$set(this.selectedDealForUpdate, 'product_total', total)
        },
        addTaskToDeal(deal_id) {
            this.drawerTaskCreate = true;
        },
        async dealOffersCreate({ drawer, productList, kpTemplateId }) {
            await this.dealOffersCreateOpen(drawer);

            if (this.$refs.dealOfferComponent) {
                this.$refs.dealOfferComponent.productList = productList;
                this.$refs.dealOfferComponent.kpTemplateId = kpTemplateId;
            }
        },

        async dealOffersCreateOpen(drawer) {
            this.centerDialogVisible = drawer;
        },

        dealOffersClose() {
            this.centerDialogVisible = false;
        },
        deleteDealClose(val) {
            this.drawerDeleteDeal = false;
            if(val){
                this.parent().closeAddModel();
            }
        },

         async afterOpen() {
            if (this.selectedDeal && this.selectedDeal.id) {
                await this.fetchData(this.selectedDeal.id);
				this.selectedDealForUpdate['client_form_id'] = this.selectedDeal.client_form_id;
                let query = {notificationable_type: 'deals', notificationable_id: this.selectedDeal.id};
                await this.updateNotifications(query);
            }
				else if (this.is_calendar && this.model_id) {
					this.fetchData(this.model_id);
				} 
				else {
                this.selectedDealForUpdate = JSON.parse(JSON.stringify(this.dealModel));
            }
            this.drawerCreateDeal = JSON.parse(JSON.stringify(this.dealCreate));
        },

        fetchData(id) {
            this.loadingData = true;
            this.fetchDealProducts(id);
            this.show(id)
                .then((res) => {
                    this.selectedDealForUpdate = JSON.parse(JSON.stringify(this.dealModel));
                    if (this.selectedDealForUpdate.deal_stage) {
                        this.stage_id = this.selectedDealForUpdate.deal_stage.id;
                    }
                    this.loadingData = false;
                })
                .catch((err) => {
                    this.loadingData = false;
                });
        },
        fetchDealProducts(id){
            if(this.$refs.dealProducts && id){
                this.$refs.dealProducts.updateList();
            }
        },
        dealStageChanged(id) {
            this.fetchData(id);
            this.listChanged();
        },
        deletingSpectator(user_id) {
            if (this.selectedDealForUpdate.id && user_id) {
                let deal_id = this.selectedDealForUpdate.id;
                this.$confirm(
                    this.$t('message.do_you_want_to_delete_participant'),
                    this.$t('message.warning'),
                    {
                        confirmButtonText: this.$t('message.yes'),
                        cancelButtonText: this.$t('message.no'),
                        type: "warning",
                    }
                )
                    .then(() => {
                        this.deleteSpectator({
                            deal_id: deal_id,
                            user_id: user_id,
                        }).then(() => {
                            this.fetchData(deal_id);
                        });
                    })
                    .catch(() => {
                        this.$message({
                            type: "warning",
                            message: this.$t('message.operation_canceled')
                        });
                    });
            }
        },

        drawerOpened(ref) {
            if (this.$refs[ref]) {
                if (_.isFunction(this.$refs[ref].opened)) {
                    this.$refs[ref].opened();
                }
            }
        },
        closeDrawer(drawer) {
            if (
                this.$refs[drawer] &&
                _.isFunction(this.$refs[drawer].closeDrawer)
            ) {
                this.$refs[drawer].closeDrawer();
            }
        },
        closeWhenDealNotCreated() {
            this.parent().closeAddModel();
        },
        dealFirstCreated() {
            this.showLastDeal().then((res) => {
                this.listChanged();
                this.selectedDealForUpdate = JSON.parse(
                    JSON.stringify(this.getNewDeal)
                );
            });
        },
        drawerClosed(ref) {
            if (
                (this.$refs.drawerDealCreateChild,
                ref === "drawerDealCreateChild")
            ) {
                if (!this.$refs.drawerDealCreateChild.dataSaved) {
                    this.parent().closeAddModel();
                }
            }

            if (this.$refs[ref]) {
                this.$refs[ref].closed();
            }
            if (this.reloadList === true) {
                this.reloadList = false;
            }
            if (_.isFunction(this.empty)) {
                this.empty();
            }
        },
        afterLeave() {
            this.activeName = "first";
            this.$refs.dealProducts.updateProducts = false;
            this.$refs.dealCorrespondence.afterLeave();
            this.$refs.dealStages.addActiveToStage(0);
            this.selectedDealForUpdate = {};
            this.emptyDealCorrespondences();
				this.$store.commit('systeamNotification/EMPTY_LIST');

        },
        listChanged() {
            this.parent().listChanged();
        },
        downloadFile(deal_id, fileName, folder) {
            let data = { deal_id: deal_id, file: fileName, folder: folder};
            this.downloadDealFile(data)
                .then((res) => {
                    const url = window.URL.createObjectURL(
                        new Blob([res.data])
                    );

                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", fileName);
                    document.body.appendChild(link);
                    link.click();
                })
                .catch((err) => {
                    this.$alert(err);
                });
        },
        archivatingDeal(deal_id) {
            this.$confirm(
                this.$t('message.do_you_want_to_archivate_task'),
                this.$t('message.warning'),
                {
                    confirmButtonText: "Да",
                    cancelButtonText: "Нет",
                    type: "warning",
                }
            )
                .then(() => {
                    this.listChanged();
                    this.archivateDeal(deal_id).then(() => {
                        this.parent().closeAddModel();
                    });
                    this.$message({
                        type: "success",
                        message: this.$t('message.archivating_completed'),
                    });
                })
                .catch(() => {
                    this.$message({
                        type: "warning",
                        message: this.$t('message.archivating_canceled'),
                    });
                });
        },
		   saveSysteamNotification(){
				if (!this.loadingButton){
					if (!this.notification_form.activity_time){
						this.$message({
							showClose: true,
							message: 'must be active time',
							type: 'warning'
						});
					}
					else {
						this.$refs['notification_form'].validate((valid) => {
						if (valid) {
							this.loadingButton = true;
							this.saveNotification(this.notification_form)
								.then((res) => {
									this.loadingButton = false;
									this.$alert(res);
									this.updateNotifications({notificationable_type: 'deals'});
									this.dialogVisible = false;
								}).catch((err) => {
									this.$alert(err);
								});
							}
						});
					}
				}
			},
		  showItems(notification_id){
				if (notification_id) {
					this.dialogTableVisible = true;
					this.loadingNotificationUser = true;
					this.getNotificationUsers(notification_id).then((res) => {
						this.loadingNotificationUser = false;
						if (res.users) {
							this.notes_users = res.users;
						}
					}).catch((err) => {
						this.loadingNotificationUser = false;
						this.$alert(err);
					});
				}
			},
		  edit(model){
			this.dialogVisible = true;
			if (!this.loadingNotifications){
				this.notificationShow(model.id).then((res) => {
					if (res.systeamNotification){
						let data = res.systeamNotification;
						this.notification_form.id = data.id;
						this.notification_form.type_id = data.type_id;
						this.notification_form.text = data.text;
						this.notification_form.activity_time = data.activity_time;
						this.notification_form.user_ids = data.user_ids;
					}
				}).catch(err => {
					console.log(err);
				});
			}
		  },
		  destroy(notification){
				if (!this.loadingNotifications){
					this.deleteNotification(notification.id).then((res) => {
						this.$alert(res);
						this.updateNotifications({notificationable_type: 'deals'});
					}).catch(err => {
						console.log(err);
					});
				}
		  },
		   openDialog(){
			this.usersInventory({all_users: 'all_users'}).then((res) => {
				this.usersList = JSON.parse(JSON.stringify(this.users));
			});
		  },
		  closedDialog(){
				this.notification_form.type_id = null;
				this.notification_form.user_ids = [];
				this.notification_form.activity_time = '';
				this.notification_form.text = '';
				this.notification_form.notificationable_id = null;
				this.usersList = [];
		  },
		  closedDialogUsers(){
			this.notes_users = [];
		  },
		closeCalendar() {      
			this.parent().closeAddModel();
      },
    },
};
</script>

<style lang="scss">
.deal-tab-scroll{
    .el-tabs__nav-wrap{
        overflow-x: auto;
        width: 100%;
        padding: 0px;
    }
    .el-tabs__nav-scroll{
        overflow-x: scroll;
        padding-bottom: 10px;
    }
    .el-tabs__nav-prev, .el-tabs__nav-next{
        display: none;
    }
    .el-tabs__nav-scroll::-webkit-scrollbar {
        height: 5px !important;
    }
    .el-tabs__nav-scroll::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 0px rgba(0, 0, 0, 0);
    }
    .el-tabs__nav-scroll::-webkit-scrollbar-thumb {
        height: 5px;
        background-color: #1f75a8;
        width: 50px;
    }
    .el-tabs__nav-scroll::-webkit-scrollbar-thumb:hover {
        background-color: #1f75a8;
    }
    .el-tabs__nav-scroll::-webkit-scrollbar:horizontal {
        height: 10px;
        padding: 4px;
    }
    .d-modal-style .el-tabs__nav-scroll::-webkit-scrollbar:horizontal {
        background-color: #080c24;
    }
    .el-tabs__nav-wrap.is-scrollable{
        padding: 0;
    }
}
.el-collapse-khan-deals .el-collapse,
.el-collapse-khan-deals .el-collapse-item__header,
.el-collapse-khan-deals .el-collapse-item__wrap{
   border: none !important;
}
.el-collapse-khan-deals .el-collapse-item{
    border: 1px dashed #274156 !important;
    .el-collapse-item__content{
        padding-bottom: 0px;
    }
    .gc-card{
        border: none !important;
        border-top: 1px dashed #274156 !important;
    }
    .el-collapse-item__header{
        padding-left: 20px;
    }
}
.l-modal-style .el-collapse-khan-deals .el-collapse-item,
.l-modal-style .el-collapse-khan-deals .gc-card
{
    border-style: solid !important;
    border-color: #ebeef5 !important;
}
.l-modal-style  .el-collapse-khan-deals .gc-card{
   border: none !important;
}
.u_p_info{
    position: absolute;
    right: 10px;
    font-size: 11px;
    cursor: pointer;
}
.d-modal-style  .text-descr{
    color: #fff !important;
}
.d-modal-style .text-descr__label {
    color: #a3a9b1 !important;
}

</style>

<style>
.KP_modal .el-dialog__body {
    width: 100%;
    background: inherit;
}
.KP_modal .el-dialog {
    background: #f8f8f8;
    border-radius: 10px;
}
.tab-right .el-upload {
    margin-top: -20px;
    margin-bottom: 5px;
}
.mir {
    display: flex;
    justify-content: space-between;
    color: #558cf5;
    border-bottom: 1px solid #d1d1d154;
    padding-top: 7px;
    cursor: pointer;
}
.mir:hover {
    background-color: #e3e3e34a;
    border-color: #d1d1d154;
}

.mirdel {
    display: flex;
    justify-content: space-between;
    /* color: #8a1e35; */
    border-bottom: 1px solid #d1d1d154;
    padding-top: 7px;
    cursor: pointer;
}
</style>
