export const model = {
    id: null,
    meeting_goal: '',
    user_id: '',
    type_id: '',
    comment: '',
    notify: false,
    begin_date: '',
    end_date: '',
    notification_time: '',
    notification_type_id: null,
    start_date: {
      date: '',
      time: ''
    },
    finish_date: {
      date: '',
      time: ''
    },
    files: [],
    filial_ids: [],
    responsible_ids: [],
    participant_ids: [],
	 status_archived: false,
	 meeting_format: '',
	 link_google_meet: '',
    created_at: '',
    updated_at: '',
  };
  