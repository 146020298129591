<template>
   <el-select
		:value="selected"
		multiple
		:collapse-tags="collapse_tags"
		@input="dispatch"
		:placeholder="placeholder || $t('message.users')"
		:size="size"
		:class="className"
		filterable 
		clearable
		@change="Cchange"
		:style="customStyle"
   >
		<el-option
			v-for="(user, index) in users"
			:key="'user-' + index"
			:label="user.name"
			:value="user.id"
		>
		</el-option>
   </el-select>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  props: {
    size: {
      default: "small",
    },
    placeholder: {
      default: null,
    },
    user_ids: {
      default: Array,
    },
	 collapse_tags:{
		default: false
	 },
	 className: {
		default: 'w-100'
	 },
	 customStyle:{
		default: ''
	 },
	 filial_ids: {
		default: null
	 }
  },
  watch: {
    user_ids: {
      handler: function () {
        this.selected = this.user_ids;
      },
      immediate: true,
    },
	 filial_ids: {
		handler: function (){
			if (this.filial_ids.length > 0){
				this.updateInventory({filial_ids: this.filial_ids});
			}
			else this.updateInventory();
		},
		deep: true
	 }
  },
  data() {
    return {
        selected: [],
    };
  },

  mounted() {
    if (this.users && this.users.length === 0) this.updateInventory();
  },
  computed: {
    ...mapGetters({
      users: "users/inventory",
    }),
  },
  methods: {
    ...mapActions({
      updateInventory: "users/inventory",
    }),
    dispatch(e) {
      this.$emit("input", e);
      this.selected = e;
    },
	 Cchange(e){
		this.$emit('c-change', e);
		this.selected = e;
	 }
  },
};
</script>