<template>
    <div :class="mode ? 'style-scuerday' : 'style-scuernight'" class=" style-scuer h__full handle-draggable ow-scroll-600">
        <div v-if="editDashboard" class="vue-draggable-handle"></div>
        <div class="task-home-two-center h__responssive">
            <div class="filterblock_s mb-5">
                <h4 class="dashboard-tile__item-title short">
                    <span class="dashboard-tile__item-title-txt text-white">{{ $t('message.conter_agent') }}</span>
                </h4>
                <div class="filter_home flex-center">
                    <!-- <div>
                        <a @click="$emit('showFullWindow'), counterGraph = true" class="full__item">
                            <span class="icon__color svg-icon svg-icon-muted svg-icon-2hx">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                    fill="none">
                                    <path opacity="0.3"
                                        d="M2 9.09998V3C2 2.4 2.4 2 3 2H9.10001L2 9.09998ZM22 9.09998V3C22 2.4 21.6 2 21 2H14.9L22 9.09998ZM2 14.9V21C2 21.6 2.4 22 3 22H9.10001L2 14.9ZM14.9 22H21C21.6 22 22 21.6 22 21V14.9L14.9 22Z"
                                        fill="currentColor" />
                                    <path
                                        d="M19.2 17.8L13.4 12L19.2 6.20001L17.8 4.79999L12 10.6L6.2 4.79999L4.8 6.20001L10.6 12L4.8 17.8L6.2 19.2L12 13.4L17.8 19.2L19.2 17.8Z"
                                        fill="currentColor" />
                                </svg>
                            </span>
                        </a>
                    </div> -->
                    <el-radio-group v-model="clientChartPeriod" size="medium">
                        <el-radio-button label="weekly">{{ $t("message.weekly") }}</el-radio-button>
                        <el-radio-button label="monthly">{{ $t("message.monthly") }}</el-radio-button>
                        <el-radio-button label="yearly">{{ $t("message.yearly") }}</el-radio-button>
                    </el-radio-group>
                    <i v-if="editDashboard" @click="$emit('remove-chart')" class="fa-solid fa-trash-can"
                        style="font-size: 18px; color: #dc2626; cursor: pointer;">
                    </i>
                </div>
            </div>

            <!-- full window -->
            <!-- <div class="fixed__pos">
                <div v-if="counterGraph"
                    :class="[counterGraph ? 'graph__full' : '', mode ? 'graph__day' : 'graph__night']">
                    <div class="graph__header">
                        <div class="filter_home">
                            <el-radio-group v-model="clientChartPeriod" size="medium">
                                <el-radio-button label="weekly">{{ $t("message.weekly") }}</el-radio-button>
                                <el-radio-button label="monthly">{{ $t("message.monthly") }}</el-radio-button>
                                <el-radio-button label="yearly">{{ $t("message.yearly") }}</el-radio-button>
                            </el-radio-group>
                        </div>
                        <div>
                            <a @click="counterGraph = false" class="graph__close"
                                :class="mode ? 'graph__closeday' : 'graph__closenight'">
                                <span class="graph__icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none">
                                        <rect opacity="0.5" x="7.05025" y="15.5356" width="12" height="2" rx="1"
                                            transform="rotate(-45 7.05025 15.5356)" fill="currentColor" />
                                        <rect x="8.46447" y="7.05029" width="12" height="2" rx="1"
                                            transform="rotate(45 8.46447 7.05029)" fill="currentColor" />
                                    </svg>
                                </span>
                            </a>
                        </div>
                    </div>

                    <div class="agents__flex">
                        <div class="agents__full">
                            <CounterColumn :key="update" :filterForm="filterForm" :period="clientChartPeriod"
                                @getClientColors="getClientColors" />
                        </div>
                        <div class="agents__full task-scroll-khan">
                            <table class="agents__table">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th></th>
                                        <th>{{ $t('message.deals') }}</th>
                                        <th>{{ $t('message.tasks') }}</th>
                                        <th>{{ $t('message.money_amount') }}</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(client, index) in clientStatistics" :key="client.id">
                                        <td>
                                            <span class="agents__circle"
                                                :style="'background:' + clientColors[index]"></span>
                                        </td>
                                        <td>
                                            <span> {{ client.full_name }} </span>
                                        </td>
                                        <td class="agents__center">{{ client.deal_count }}</td>
                                        <td class="agents__center">{{ client.task_count }}</td>
                                        <td class="agents__center">{{ client.money_amount |
                                            formatMoney(2) }}</td>
                                        <td class="agents__view"><span @click="showSelectedClient(client)"
                                                class="el-icon-view"></span></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div> -->

            <div class="agents__box agents__box-mobil">
                <div class="agents__half mw-100">
                    <CounterColumn :filterForm="filterForm" :period="clientChartPeriod"
                        @getClientColors="getClientColors" />
                </div>
                <div class="agents__half task-scroll-khan mw-100">
                    <table class="agents__table">
                        <thead>
                            <tr>
                                <th></th>
                                <th></th>
                                <th>{{ $t('message.deals') }}</th>
                                <th>{{ $t('message.tasks') }}</th>
                                <th>{{ $t('message.money_amount') }}</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(client, index) in clientStatistics" :key="client.id">
                                <td>
                                    <span class="agents__circle" :style="'background:' + clientColors[index]"></span>
                                </td>
                                <td>
                                    <span> {{ client.full_name }} </span>
                                </td>
                                <td class="agents__center">{{ client.deal_count }}</td>
                                <td class="agents__center">{{ client.task_count }}</td>
                                <td class="agents__center">{{ client.money_amount | formatMoney(2) }}
                                </td>
                                <td class="agents__view"><span @click="showSelectedClient(client)"
                                        class="el-icon-view"></span></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import CounterColumn from "@/components/chart/CounterColumn.vue";
export default {
    components: {
        CounterColumn,
    },
    props: {
        mode: {
            type: Boolean,
            default: () => false
        },
        editDashboard: {
            type: Boolean,
            default: () => false
        },
        allPeriodChange: {
            type: String,
            default: () => "weekly"
        },
        update: Number,
        filterForm: Object,
    },
    data() {
        return {
            counterGraph: false,
            clientChartPeriod: "weekly",
            clientColors: [],
        }
    },
    watch: {
        allPeriodChange(val) {
            this.clientChartPeriod = val
        }
    },
    computed: {
        ...mapGetters({
            clientStatistics: "home/clientStatistics",
        })
    },
    methods: {
        getClientColors(value) {
            this.clientColors = value;
        },
    },
}
</script>

<style lang="scss" scoped></style>