import { i18n } from '@/utils/i18n';

export const columns = {
    id : {
        show: true,
        title: i18n.t('message.n'),
        sortable: true,
        column: 'id'
    },
    header : {
        show: true,
        title: i18n.t('message.header'),
        sortable: true,
        column: 'header'
    },
	 project : {
		show: true,
		title: i18n.t('message.project'),
		sortable: true,
		column: 'project'
    },
	user : {
		show: true,
		title: i18n.t('message.user'),
		sortable: true,
		column: 'user'
   },
	link : {
		show: true,
		title: i18n.t('message.link'),
		sortable: true,
		column: 'link'
    },
    script : {
        show: true,
        title: i18n.t('message.script'),
        sortable: true,
        column: 'script'
    },
	deal : {
		show: true,
		title: i18n.t('message.deal'),
		sortable: true,
		column: 'deal'
    },
	view : {
		show: true,
		title: i18n.t('message.view'),
		sortable: true,
		column: 'view'
    },
    created_at : {
        show: true,
        title: i18n.t('message.created_at'),
        sortable: true,
        column: 'created_at'
    },
    updated_at : {
        show: false,
        title: i18n.t('message.updated_at'),
        sortable: true,
        column: 'updated_at'
    },   
    settings : {
        show: true,
        title: i18n.t('message.settings'),
        sortable: false,
        column: 'settings'
    },

};
