import { render, staticRenderFns } from "./PaymentsByPeriodArea.vue?vue&type=template&id=5015dee4&scoped=true&"
import script from "./PaymentsByPeriodArea.vue?vue&type=script&lang=js&"
export * from "./PaymentsByPeriodArea.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5015dee4",
  null
  
)

export default component.exports