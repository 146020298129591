import _ from 'lodash';
import { mapActions, mapGetters } from "vuex";

export default {

  data() {
    return {
      file_limit: 10,
      newMiniTask: {
        text: '',
        number: '',
        is_done: false,
        comments: [],
        fileList: [],
        date: [],
        users: [],
      },
      newTask: '',
      dialogPictureVisible: false,
      selectedImage: {},
      selectedMiniTask: {},
      new_form_comment: {
        comment: '',
        index: -1,
      },

      miniTaskNewComment: {
        comment: '',
        index: -1,
      },
      waiting: false
    }
  },
  watch: {
    addingTask: {
        handler: async function(newVal) {
          if(newVal && this.$refs.checkListCreate){
            await this.closeAllUpdates();
            setTimeout(() => {
              this.focusRef('checkListCreate');
            }, 200);
          }
        },
        deep: true,
        immediate: true
    },
  },
  computed: {
    ...mapGetters({
      users: "users/inventory",
      authUser: "auth/user",
    }),
  },
  methods:{
      ...mapActions({
        updateTaskCounts: "tasks/taskCounts", 
        deleteMiniTaskFiles: "checkListMiniTasks/deleteMiniTaskFiles",
        destroyMiniTask: "checkListMiniTasks/destroy",
        destroyCheckList: "taskCheckLists/destroy",
        donloadFile: "tasks/donloadFile",
      }),
	    checkChange(participants){
			if (participants.length > this.form.participants.length) {
				let user_ids = _.map(this.form.participants, 'id');
				let array_diff = _.difference(participants, user_ids);
				let array = _.isEmpty(this.form.participants) ? participants : array_diff;
				
				let filtered_users = this.users.filter(user => array.includes(user.id)).map(user => {
					return {
						id: null,
						user_id: user.id,
						name: user.name,
						email: user.email,
						phone: user.phone,
						avatar: user.avatar,
						permission: false
					}
				});

				this.form.participants = this.form.participants.concat(filtered_users);
			}
			else if (participants.length < this.form.participants.length){
				let user_ids = _.map(this.form.participants, 'id');
				let diff_ids = _.difference(user_ids, participants);

				this.form.participants = this.form.participants.filter(user => !diff_ids.includes(user.id));
			}
		},
      focusRef(ref){
        if(this.$refs[ref] && this.$refs[ref][0]){
          this.$refs[ref][0].focus();
        }else if(this.$refs[ref]){
          this.$refs[ref].focus();
        }
      },
      zoomImage(){
        if(this.$refs.ImageContainer){
          const zoomElement = this.$refs.ImageContainer;
          let zoom = 1;
          const ZOOM_SPEED = 0.1;
          document.addEventListener("wheel", function(e) {
              if(e.deltaY > 0){
                  zoomElement.style.transform = `scale(${zoom += ZOOM_SPEED})`;
              }else{
                  zoomElement.style.transform = `scale(${zoom -= ZOOM_SPEED})`;
              }
          });
        }
      },
      setCoverImage(file){
        if(this.form.cover_image && file.name === this.form.cover_image){
          this.form.cover_image = '';
          this.$set(this.form, 'cover_image', '');
        }else{
          this.$set(this.form, 'cover_image', file.name);
        }
      },

      urlify(text) {
        let urlRegex = /(?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#\/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[A-Z0-9+&@#\/%=~_|$])/igm;
        return text.replace(urlRegex, function(url) {
          return '<a target="_blank" href="' + url + '">' + url + '</a>';
        })
      },
      showImage(file){
        if(file.name){
          if(['image/png', 'image/jpeg', 'jpeg', 'jpg', 'png'].includes(file.type || (/[^.]*$/.exec(file.name)[0])) || (file.raw && ['image/png', 'image/jpeg'].includes(file.raw.type))){
            this.selectedImage = file;
            this.dialogPictureVisible = true;
          }else{
            let link = document.createElement('a');
            link.href = file.url;
            link.download = file.name;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
        }
      },
      removeImage(index, fileList){
        if(fileList[index].name === this.form.cover_image){
          this.form.cover_image = '';
        }
        fileList.splice(index, 1);
      },
      handleExceed(files, fileList) {
        this.$message.warning(this.$t('message.file_limit_warning', {number: this.file_limit}));
      },
      handleRemove(file, fileList){
        this.updateFile.forEach((element, index) => {
          if(element.name === file.name ){
            this.updateFile.splice(index, 1);
          }
        });
      },
      createImageList(file, fileList) {
          if (fileList.length > this.file_limit) {
            fileList.pop();
            return this.$message({
              type: "warning",
              message: this.$t('message.file_limit_warning', {number: this.file_limit}),
            });
          }else if (file.size > 5000000) {
            fileList.pop();
            return this.$message({
              type: "warning",
              message: this.$t('message.file_size_limit_warning', {number: 5}),
            });
          }else if(this.updateFile.some(item => item.name == file.name)) {
            this.updateFile.forEach((element, index) => {
              if(element.name === file.name && element.size === file.size){
                fileList.pop();
                return this.$message({
                  type: "warning",
                  message: this.$t('message.file_already_exists'),
                });
              }
            });
          }else{
            let new_file = file.raw;
            new_file.url = URL.createObjectURL(file.raw)
            this.updateFile.push(new_file);
          }
      },
      submitFiles(){
        let formData = new FormData();

        if (_.size(this.updateFile) > 0) {
          for (const key in this.updateFile) {
            if (this.updateFile.hasOwnProperty(key)) {
              const element = this.updateFile[key];
              formData.append(`files[${key}]`, element);
            }
          }
        }
        this.saveFiles(formData)
      },
      editFormComment(comment, index){
        this.new_form_comment.id = comment.id ? comment.id : null;
        this.new_form_comment.comment = comment.comment;
        this.new_form_comment.index = index;
        this.focusRef('FormCommentRef');
      },
      cancelSavingFormComment(){
        this.new_form_comment = {
          comment: '',
          index: -1,
        };
      },

      saveFormComment(){
        if(this.new_form_comment.comment){
          if(!this.form.comments){
            this.$set(this.form, 'comments', []);
          }
          let newDate = new Date();
          if(this.new_form_comment.index >= 0){
            this.form.comments[this.new_form_comment.index] = {
              'id': this.new_form_comment.id ? JSON.parse(JSON.stringify(this.new_form_comment.id)) : null,
              'user_id': this.authUser.id,
              'comment': JSON.parse(JSON.stringify(this.new_form_comment.comment)),
              'updated_at': this.dateTimeFormat(newDate),
            };
          }else{
            this.form.comments.push({
              'id': this.new_form_comment.id ? JSON.parse(JSON.stringify(this.new_form_comment.id)) : null,
              'user_id': this.authUser.id,
              'comment': JSON.parse(JSON.stringify(this.new_form_comment.comment)),
              'updated_at': this.dateTimeFormat(newDate),
            });
          }

          this.new_form_comment = {
            comment: '',
            index: -1,
          };
        }

        setTimeout(() => {
          this.focusRef('FormCommentRef');
        }, 100);
      },

      destroyComment(comment){
        this.$confirm(
          this.$t('message.do_you_really_want_to_do_this'),
          this.$t('message.warning'), {
            confirmButtonText: this.$t('message.yes'),
            cancelButtonText: this.$t('message.no'),
            type: "warning"
          }
        )
        .then(() => {
          this.form.comments = this.form.comments.filter(item => item.number != comment.number);
          this.form.comments.map((item, index) => item.number = index + 1);
        })
        .catch(() => {
          this.$message({
            type: "warning",
            message: this.$t('message.operation_canceled')
          });
        });

      },

      parentClicked(e, miniTask){
        if(e.target.tagName === 'DIV' ){
          this.updateMiniTask(miniTask);
        }
      },

      updateParticipants(users){
        users.forEach(user => {
          this.form.participants.push(user);
        });
      },
      deleteParticipant(participant_id){
        this.form.participants = this.form.participants.filter(user => user.id !== participant_id);
		this.participants = this.participants.filter(user_id => user_id != participant_id);
      },
      createCheckList(list_name){
        let number = this.form.checkLists.length + 1;
        this.form.checkLists.push({number: number, name: JSON.parse(JSON.stringify(list_name)), percentage: 0, miniTasks: []});
        this.newTask = '';
        this.addingTask = false;
        this.addMiniTask(this.form.checkLists[this.form.checkLists.length-1]);
      },

      async updateCheckList(list){
        await this.closeAllUpdates();
        this.newTask = JSON.parse(JSON.stringify(list.name));
        this.$set(list, 'updating', true);
        setTimeout(() => {
          this.focusRef('checkListUpdate');
        }, 100);
      },
      saveCheckList(list){
        this.$set(list, 'name', JSON.parse(JSON.stringify(this.newTask)));
        this.$set(list, 'updating', false);
        this.newTask = '';
      },

      closeCheckList(list){
        this.$set(list, 'updating', false);
        this.newTask = '';
      },
      deleteCheckList(checkList) {
        this.$confirm(
          this.$t('message.do_you_really_want_to_do_this'),
          this.$t('message.warning'), {
            confirmButtonText: this.$t('message.yes'),
            cancelButtonText: this.$t('message.no'),
            type: "warning"
          }
          )
          .then(() => {
            if(checkList.id){
              this.destroyCheckList(checkList.id)
                .then(res => {
                  this.form.checkLists.splice((checkList.number-1), 1);
                  this.form.checkLists.map((item, index) => item.number = index + 1)
                }).catch(err => {
                  this.$message({
                    type: "warning",
                    message: this.$t('message.operation_canceled')
                  });
                })
            }else{
              this.form.checkLists.splice((checkList.number-1), 1);
              this.form.checkLists.map((item, index) => item.number = index + 1)
            }

          })
          .catch(() => {
            this.$message({
              type: "warning",
              message: this.$t('message.operation_canceled')
            });
          });
      },
      updateDates(dates){
        if(dates){
          this.form.begin_date = dates[0];
          this.form.end_date = dates[1];
        }else{
          this.form.begin_date = '';
          this.form.end_date = '';
        }

      },
      closeAllUpdates(){
        for(let list of this.form.checkLists){
          this.$set(list, 'addingMiniTask', false);
          this.$set(list, 'updating', false);
          for(let miniTask of list.miniTasks){
            this.$set(miniTask, 'updating', false);
          }
        }
      },
      async addMiniTask(list){
        await this.closeAllUpdates();
        this.$set(list, 'addingMiniTask', true);
        setTimeout(() => {
          this.focusRef('miniTaskInput');
        }, 100);
      },
      emptyNewMiniTask(){
        this.newMiniTask = {
          text: '',
          number: '',
          is_done: false,
          comments: [],
          fileList: [],
          date: [],
          users: [],
        };
      },
      saveMiniTaskComment(miniTask, index){
        if(this.miniTaskNewComment.comment){
          if(!miniTask.comments){
            this.$set(miniTask, 'comments', []);
          }
          let newDate = new Date();
          let data = {
            'id': this.miniTaskNewComment.id ? JSON.parse(JSON.stringify(this.miniTaskNewComment.id)) : null,
            'user_id': this.authUser.id,
            'comment': JSON.parse(JSON.stringify(this.miniTaskNewComment.comment)),
            'updated_at': this.dateTimeFormat(newDate),
          };

          if(this.miniTaskNewComment.index >= 0){
            miniTask.comments[this.miniTaskNewComment.index] = data;
          }else{
            miniTask.comments.push(data);
            setTimeout(() => {
              this.scrollToEnd(('miniTaskCommentContainer_'+index));
            }, 100);
          }

          this.miniTaskNewComment = {
            comment: '',
            index: -1,
          };
        }

        setTimeout(() => {
          this.focusRef('MiniTaskNewCommentRef');
        }, 100);
      },

      miniCommentScrollAndFocus(index) {
        this.scrollToEnd(('miniTaskCommentContainer_'+index));
        this.focusRef('MiniTaskNewCommentRef');
      },

      editMiniTaskComment(min_comment, comment_index){
        this.miniTaskNewComment.id = min_comment.id ? min_comment.id : null;
        this.miniTaskNewComment.comment = min_comment.comment;
        this.miniTaskNewComment.index = comment_index;
        this.focusRef('MiniTaskNewCommentRef');
      },
      cancelSavingMiniTaskComment(){
        this.miniTaskNewComment = {
          comment: '',
          index: -1,
        };
      },
      scrollToEnd(id){
        var el = document.getElementById(id);
        el.scrollTo(0, (el.scrollHeight));
      },
      updateMiniTaskFiles(miniTask){
        this.selectedMiniTask = miniTask;
      },
      updateFileInList(file, fileList){
          this.selectedMiniTask.fileList.push(file);
      },
      removeUpdateFileFromList(file, fileList){
        if(file.id){
          this.deleteMiniTaskFiles({mini_task_id: this.selectedMiniTask.id, file: file.name});
        }
        this.selectedMiniTask.fileList.forEach((element, index) => {
          if(element.name === file.name ){
            this.selectedMiniTask.fileList.splice(index, 1);
          }
        });
      },

      setFileInList(file, fileList){
        this.newMiniTask.fileList.push(file);
      },
      removeFileFromList(file, fileList){

          this.newMiniTask.fileList.forEach((element, index) => {
            if(element.name === file.name ){
              this.newMiniTask.fileList.splice(index, 1);
            }
          });

      },

      updateMiniTaskUsers(miniTask, user){
        setTimeout(() => {
          if ('checked' in user){
            if(user.checked){
              miniTask.users.push(user.id)
            }else{
              miniTask.users = miniTask.users.filter(el => el != user.id);
            }
          }else{
            miniTask.users.push(user.id);
          }
        }, 100);

      },
      updateCurrentMiniTask(miniTask){
        this.$set(miniTask, 'text', JSON.parse(JSON.stringify(this.newMiniTask.text)));
        this.$set(miniTask, 'updating', false);
        this.emptyNewMiniTask();
      },
      updateMiniTaskList(checkList){
        if(this.newMiniTask.text){
          let number = JSON.parse(JSON.stringify(checkList.miniTasks.length + 1));
          this.newMiniTask.number = number;
          checkList.miniTasks.push({
            text: this.newMiniTask.text,
            number: number,
            is_done: false,
            comments: this.newMiniTask.comments,
            fileList: this.newMiniTask.fileList,
            date: this.newMiniTask.date,
            users: this.newMiniTask.users,
          });
          this.emptyNewMiniTask();
          this.checkListPercentage(checkList.number);
        }
        setTimeout(() => {
          this.focusRef('miniTaskInput');
        }, 50);


      },
      checkListPercentage(checkListNumber){
        if(this.form.checkLists && this.form.checkLists.length > 0){
          let allCount = this.form.checkLists[checkListNumber-1].miniTasks.length;
          let selectedCount = this.form.checkLists[checkListNumber-1].miniTasks.filter(task => task.is_done === true);
          selectedCount = selectedCount.length;
          let percentage = 0;
          if(allCount > 0){
            percentage = selectedCount*100/allCount;
          }
          this.form.checkLists[checkListNumber-1].percentage =  Math.round(percentage);
        }
      },
      closeUpdateMiniTask(miniTask){
        this.emptyNewMiniTask();
        this.$set(miniTask, 'updating', false);
      },
      async updateMiniTask(miniTask){
        this.newMiniTask.text = JSON.parse(JSON.stringify(miniTask.text));
        await this.closeAllUpdates();
        this.$set(miniTask, 'updating', true);
        setTimeout(() => {
          this.focusRef('miniTaskInput');
        }, 100);

      },
      deleteMiniTask(list, index){
        this.$confirm(
          this.$t('message.do_you_really_want_to_do_this'),
          this.$t('message.warning'), {
            confirmButtonText: this.$t('message.yes'),
            cancelButtonText: this.$t('message.no'),
            type: "warning"
          }
        )
          .then(() => {
            if(list.miniTasks[index] && list.miniTasks[index].id){
              this.destroyMiniTask(list.miniTasks[index].id)
                .then(res => {
                  list.miniTasks.splice(index, 1);
                  list.miniTasks.map((item, index) => item.number = index + 1);
                }).catch(err => {
                  this.$message({
                    type: "warning",
                    message: this.$t('message.operation_canceled')
                  });
                });

            }else{
              list.miniTasks.splice(index, 1);
              list.miniTasks.map((item, index) => item.number = index + 1);
            }
          })
          .catch(() => {
            this.$message({
              type: "warning",
              message: this.$t('message.operation_canceled')
            });
          });
      },
      updateTaskLabels(taskLabels){
        this.form.labels = taskLabels;
      },
      removeLabelFromTask(label){
        this.form.labels = this.form.labels.filter(item => item.id != label.id)
      },

      updateComment(comment){

        this.comment = comment.comment;
        this.form.comments = this.form.comments.filter(item => item.number !== comment.number);
        this.form.comments.map((item, index) => item.number = index + 1);
      },
      listChanged() {
      },
      closeAddModel() {
          this.drawerCreateDeal = false;
          if(this.reloadList === true){
              this.updateDealsList().then(res => this.reloadList === false);
          }
      },
      drawerClosed(ref) {
        if(ref === 'drawerDatesChild'){
            this.taskDates = [];
        }

          if (this.$refs[ref]) {
              this.$refs[ref].closed()
          }
          if (this.reloadList === true) {

          }
      },
      drawerOpened(ref) {
          if (this.$refs[ref]) {
              if (_.isFunction(this.$refs[ref].opened)) {
                  this.$refs[ref].opened()
              }
          }
      },
      closeDrawer(drawer) {
          if (this.$refs[drawer] && _.isFunction(this.$refs[drawer].closeDrawer)) {
            this.$refs[drawer].closeDrawer();
          }
          if (this.$refs[drawer]) {
              this.participants = false;
              this.addingTask = false;
              this.term = false;
              this.labels = false;
              this.addingMiniTask = false;
              this.updateMiniTaskDrawer = false;
              this.drawerDeleteTask = false;
          }
      },

      generateFormData(){
        let formData = new FormData();
        for (const key in this.form) {
          if (key != "comments" && key != "checkLists" && key != "participants" && key != "date" &&  key != "labels") {
            if (this.form[key]) {
              formData.append(key, this.form[key]);
            } else {
              formData.append(key, "");
            }
          }else{
            formData.append(key, JSON.stringify(this.form[key]));
          }
        }
        if (_.size(this.updateFile) > 0) {
          for (const key in this.updateFile) {
            if (this.updateFile.hasOwnProperty(key)) {
              const element = this.updateFile[key];
              formData.append(`files[${key}]`, element);
            }
          }
        }
        this.form.checkLists.forEach((list, list_index) => {
          list.miniTasks.forEach((mini, mini_index) => {
            if(mini.fileList){
              mini.fileList.forEach((file, file_index)=> {
                if (mini.fileList.hasOwnProperty(file_index)) {
                  formData.append(`mini_files[${list_index}][${mini_index}][${file_index}]`, file.raw);
                }
              });
            }
          })
        });
        return formData;
      },

      submit(close = true) {
        if(!this.form.task_board_id){
          this.form.task_board_id = this.board_id;
        }
        if(!this.form.user_id){
          this.form.user_id = this.authUser.id;
        }
        if(this.form.user){
          this.form.user_id = this.form.user.id;
        }
        if(this.taskable && this.taskable.taskable_id  && this.taskable.taskable_type){
          this.form.taskable_id = this.taskable.taskable_id;
          this.form.taskable_type = this.taskable.taskable_type;
        }
        let formData = this.generateFormData();
        if(this.form.id){
          formData.append('_method', 'PUT');
        }
        this.$refs["form"].validate((valid) => {
            if (valid) {
                this.loadingButton = true;
                this.save(formData)
                    .then((res) => {
                        this.loadingButton = false;
                        this.$alert(res);
                        this.parent().listChanged();
                        this.submitFiles();
                        this.updateTaskCounts();
                        if(this.form.deal_id > 0){
                          this.updateDealTasks(this.form.deal_id);
                        }
                        if (close) this.close();
                    })
                    .catch((err) => {
                        this.loadingButton = false;
                        this.$alert(err);
                    });
            }
        });
      },
      afterLeave(){
        this.finishDateCheck = false;
        this.finish_date = '';
        this.taskDates = [];
        this.fileList = [];
        this.updateFile = [];
		this.participants = [];
        this.empty();
      },
  }
}
