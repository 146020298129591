<template>
  <div>
    <div class="app-modal__box">
      <div class="app-modal__in">
        <div class="app-modal__header d-flex f-between p-5 m-title-modal">
          <p class="large--title m-0">{{
              $t("message.edit_m", { m: $t("message.task"), })  +'   № ' + form.id
            }}</p>
          <div class="d-flex align-center">
            <el-button
                size="medium"
                class="mr-4 mt-1"
                v-if="(form.finish_date && form.status === 4 && ((authUser.is_super_admin ||(authUser.id === (form.user ? form.user.id : 0))) || authUser.control_user.task_control) && !checkedAll.includes(false))"
                type="warning"
                @click="archivatingTask(form.id)"
                >
                {{ $t('message.add_to_completed_tasks') }}
            </el-button>

            <crm-store-update-close
                size="mini"
                :permission="$options.name"
                :button_type="'store'"
                :loading="loadingButton"
                @c-submit="submit(true)"
                @c-close="close()"
            ></crm-store-update-close>
          </div>
        </div>
      </div>

      <div v-loading="loadingData" class="app-modal__fulls p-5">
            <el-form ref="form" :model="form" :rules="rules" label-position="top">
                <div class="gc-card rounded-sm mb-4 p-3 p-relative">
                    <el-row :gutter="20">
                        <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                            <el-form-item :label="$t('message.name')" prop="name" class="label_mini mb-0">
                                <el-input
                                class="mt-2"
                                autosize
                                type="textarea"
                                :placeholder="$t('message.name')"
                                v-model="form.name"
                                >
                                </el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                            <el-form-item :label="$t('message.priority')" prop="priority" class="label_mini mb-0">
                                <select-priority
                                    :size="'medium'"
                                    :placeholder="$t('message.priority')"
                                    :id="form.priority"
                                    v-model="form.priority"
                                >
                                </select-priority>
                            </el-form-item>
                        </el-col>
                    </el-row>

                    <el-row :gutter="20">
                        <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="6">
                            <el-form-item   :label="$t('message.task_board_id')" prop="task_board_id"
                                class="mobil-none label_mini mb-0">
                                <select-board
                                    :size="'medium'"
                                    :placeholder="$t('message.task_board_id')"
                                    :id="form.task_board_id"
                                    v-model="form.task_board_id"
                                >
                                </select-board>
                            </el-form-item>
                        </el-col>
                        <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="6">
							<!-- prop="project_id" -->
                          <el-form-item  class="mobil-none mb-0" :label="$t('message.projects')"> 
                            <select-projects v-model="form.project_id" :id="form.project_id"></select-projects>
                          </el-form-item>
                        </el-col>
                    </el-row>

                    <el-row :gutter="20">
                        <el-col :span="24">
                            <span class=" d-block p-0 m-0"> {{ $t('message.deadline') }} </span>
                        </el-col>
                        <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                            <div class=" pl-0 p-2">
                                <el-date-picker
                                    popper-class="mobil-el-date-picker"
                                    class="w-100"
                                    v-model="form.date"
                                    size="medium"
                                    type="datetimerange"
                                    range-separator="/"
                                    :start-placeholder="$t('message.from')"
                                    :end-placeholder="$t('message.to')"
                                    format="yyyy-MM-dd HH:mm"
                                    value-format="yyyy-MM-dd HH:mm">
                                </el-date-picker>
                            </div>
                        </el-col>

                        <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                            <div class="data-task-cheek" v-if="(authUser.is_super_admin || (authUser.id === (form.user ? form.user.id : 0)))">
                                <div class="my-button pl-4"  style="width: 300px;">
                                    <el-date-picker
                                        class="sroc"
                                        v-model="form.finish_date"
                                        type="date"
                                        :disabled="!finishDateCheck"
                                        :placeholder="$t('message.date')"
                                        format="yyyy-MM-dd"
                                        value-format="yyyy-MM-dd">
                                    </el-date-picker>
                                    <span class="ml-2 p-1 rounded" :class="finishDateCheck ? 'task_done' : (form.status === 4 ? 'task_for_check' : 'task_in_process_1')"> {{ finishDateCheck ? $t("message.completed") : ((taskPercentage === 100) ? $t("message.check_task") : $t("message.in_process"))}}  </span>
                                </div>
                                <!-- <el-checkbox v-if="form.status === 4 && (authUser.is_super_admin || (authUser.id === (form.user ? form.user.id : 0)))" class="mt-2 ml-4 mr-0" size="mini" v-model="finishDateCheck" :label="$t('message.task_checked')" border></el-checkbox> -->
                                <el-checkbox v-if="form.status === 4 && !checkedAll.includes(false)" class="mt-2 ml-4 mr-0" size="mini" v-model="finishDateCheck" :label="$t('message.task_checked')" border></el-checkbox>
                                <el-checkbox v-if="miniTasksCount === 0" class="mt-2 ml-2" size="mini" v-model="taskToChecking" :label="$t('message.ready_to_check')" border></el-checkbox>
                            </div>
							<div class="data-task-cheek" v-else-if="active_participants.some(o => o.user_id == authUser.id)">
								<el-checkbox 
									v-if="taskPercentage === 100"
									class="mt-2 ml-4 mr-0" 
									size="mini" 
									v-model="task_checked" 
									:label="$t('message.task_checked')" 
									border
									@change="changeTaskChecked(task_checked, authUser.id)"
								>
								</el-checkbox>
								<el-button v-if="taskPercentage === 100" type="primary" size="small" @click="dialogTaskComment = true">Task Comment</el-button>
                                <el-checkbox v-if="miniTasksCount === 0" class="mt-2 ml-2" size="mini" v-model="taskToChecking" :label="$t('message.ready_to_check')" border></el-checkbox>
                                <div class="my-button pl-4"  style="width: 300px;">
                                    <!-- <el-date-picker 
                                        class="sroc"
                                        v-model="form.finish_date"
                                        type="date"
                                        :disabled="!finishDateCheck"
                                        :placeholder="$t('message.date')"
                                        format="yyyy-MM-dd"
                                        value-format="yyyy-MM-dd">
                                    </el-date-picker>
                                    <span class="ml-2 p-1 rounded" :class="finishDateCheck ? 'task_done' : (form.status === 4 ? 'task_for_check' : 'task_in_process_1')"> {{ finishDateCheck ? $t("message.completed") : ((taskPercentage === 100) ? $t("message.check_task") : $t("message.in_process"))}}  </span>
									-->
                            	</div>
							</div>
                        </el-col>
                    </el-row>
                </div>

                <el-row :gutter="20">
                    <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                        <div class="my-new-card-style">
                            <div class="gc-card-users__info mt-2" >
                                <div class="d-flex mb-3">
                                    <span class="font-bold mt-3 mr-2"> {{ $t('message.participants') }} </span>
                                    <!-- <div class="button-style-my w-200" v-popover:participantsMy.bottom >
                                        Добавить
                                    </div>
                                    <popover name="participantsMy"  :width="600" class="ali-aa">
                                        <div>
                                            <el-transfer
                                            :titles="[$t('message.others'), $t('message.responsible')]"
                                            :props="{key: 'id', label: 'name'}"
                                            v-model="form.participants"
                                            filterable
                                            :filter-placeholder="$t('message.search')"
                                            :data="JSON.parse(JSON.stringify(users.filter(el => el.id != authUser.id)))">
                                            </el-transfer>
                                        </div>
                                    </popover> -->
                                    <el-popover
                                        popper-class="ali-elp"
                                        placement="left"
                                        v-model="visible2"
                                        :title="$t('message.users')"
                                        trigger="click">
                                        <div class="mb-5">
                                            <el-transfer
												:titles="[$t('message.others'), $t('message.responsible')]"
												:props="{key: 'id', label: 'name'}"
												v-model="participants"
												filterable
												:filter-placeholder="$t('message.search')"
												:data="JSON.parse(JSON.stringify(users.filter(el => el.id != authUser.id)))"
												@change="checkChange"
											>
                                            </el-transfer>
                                        </div>
                                        <div style="text-align: right; margin: 0">
                                          <el-button size="mini" type="text" @click="visible2 = false"> Закрыть </el-button>
                                        </div>

                                        <el-button slot="reference" class="button-style-my" style="border-radius: 0px !important;">
                                            Добавить
                                        </el-button>
                                    </el-popover>
                                </div>

                                <div class="users__info" v-if="form.participants && form.participants.length > 0">
                                    <div class="task_user_give">
                                      <div class="itme-userss">
                                          <el-popover
                                              placement="top"
                                              popper-class="p-0 myir"
                                              width="300"
                                              trigger="click">
                                              <div class="click-block">
                                                  <div class="top-u-click">
                                                      <img class="task_user_give_img" :src="form.user ? (form.user.avatar ? form.user.avatar : '/img/flag/employee.svg') : '/img/flag/employee.svg'" alt="">
                                                      <div class="tt-name">
                                                          <div>{{ form.user ? form.user.name : '' }}</div>
                                                          <span>{{ form.user ?  form.user.email : ''}}</span>
                                                      </div>
                                                  </div>
                                                  <ul>
                                                      <li>
                                                          <span><i class="el-icon-warning-outline"></i>ID: {{form.user ? form.user.id : ''}}</span>
                                                      </li>
                                                      <li>
                                                          <span><i class="el-icon-mobile-phone"></i>Tel: {{form.user ? form.user.phone : ''}}</span>
                                                      </li>
                                                  </ul>
                                              </div>

                                              <el-button slot="reference">
                                                  <div class="task_user_give_names"><img class="task_user_give_img" :src="form.user ? (form.user.avatar ? form.user.avatar : '/img/flag/employee.svg') : '/img/flag/employee.svg'" alt=""></div>
                                              </el-button>
                                          </el-popover>
                                      </div>
                                    </div>
                                    <i class="el-icon-arrow-right ml-1 mr-1"></i>
                                    <div class="d-flex task_user_accept">
										<div class="itme-userss">
											<el-popover
												placement="top"
												popper-class="p-0 myir"
												width="300"
												v-for="participant in form.participants" :key="participant.id"
												trigger="click">
												<div class="click-block">
													<div class="top-u-click">
														<img class="task_user_give_img" :src="participant.avatar ? participant.avatar : '/img/flag/employee.svg'" alt="">
														<div class="tt-name">
															<div>{{ participant.name }}</div>
															<span>{{ participant.email ? participant.email : ''}}</span>
														</div>
													</div>
													<ul>
														<li>
															<span><i class="el-icon-warning-outline"></i>ID: {{ participant.user_id }}</span>
														</li>
														<li>
															<span><i class="el-icon-mobile-phone"></i>Tel: {{ participant.phone }}</span>
														</li>
														<li v-if="(authUser.is_super_admin || (authUser.id === (form.user ? form.user.id : 0)))">
															<el-checkbox v-model="participant.permission" :label="$t('message.permission')" border></el-checkbox>
														</li>
													</ul>
													<div v-if="(authUser.is_super_admin || (authUser.id === (form.user ? form.user.id : 0)))" class="delet">
														<el-button size="small" class="w-100" type="danger" @click="deleteParticipant(participant.id)">{{ $t('message.delete_participant_from_list') }}</el-button>
													</div>
												</div>

												<el-button slot="reference">
													<img class="task_user_give_img" :src="participant.avatar ? participant.avatar : '/img/flag/employee.svg'" alt="">
												</el-button>
											</el-popover>
										</div>
                                    </div>
									
                                </div>
								<div v-if="form.participants && form.participants.length > 0">
									<ul>
										<li v-for="user in active_participants" :key="'user-'+user.id">
											{{ user.name }}  <i class="el-icon-arrow-right ml-1 mr-1"></i> {{ user.task_checked ? $t('message.completed') : $t('message.in_process') }} > {{ user.checked_at }}
										</li>
									</ul>
								</div>
                            </div>
                            <!-- end user  add -->
                        </div>
                    </el-col>

                    <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                        <div class="my-new-card-style">
                            <div class="u-clearfix mt-2">
                                <div class="d-flex mb-3">
                                    <div class="font-bold mt-3 mr-2">{{ $t('message.labels') }}</div>
                                    <el-button
                                        @click="labels = true"
                                        class="button-style-my"
                                        style="width: 93px; border-radius: 0px !important;">
                                        Добавить
                                    </el-button>
                                </div>

                                <div class="d-flex f-wrap" v-if="form.labels && form.labels.length > 0">
                                    <div
                                        class="card-label mod-card-detail mb-2"
                                        title=""
                                        :class="label.color ? label.color.name : 'color_1'"
                                        v-for="label in form.labels" :key="label.id"
                                        >
                                        <span class="label-text"> {{ label.name }}
                                            <i class="hover-dis el-icon-circle-close" @click="removeLabelFromTask(label)"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <!-- end metka  add -->
                        </div>
                    </el-col>
                </el-row>

                <div class="my-new-card-style">
                    <div class="u-clearfix mt-2">
                        <div class="d-flex mb-3">
                            <div class="font-bold mt-3 mr-2">{{$t('message.attachments')}}</div>
                            <!-- <el-button @click="labels = true" class="button-style-my" style="width: 93px; border-radius: 0px !important;">
                                Добавить
                            </el-button> -->
                            <el-upload
                                v-model="form.files"
                                class="upload-demo wu-100 "
                                action="/"
                                :limit="10"
                                :accept="fileAccepts"
                                :on-exceed="handleExceed"
                                :on-change="createImageList"
                                :on-remove="handleRemove"
                                :auto-upload="false"
                                :show-file-list="false"
                                >
                                <div class="button-style-my">
                                    {{ $t('message.add') }}
                                </div>
                            </el-upload>
                        </div>

                        <el-row :gutter="20"  v-if="[...updateFile, ...form.files] && [...updateFile, ...form.files].length > 0">
                            <el-col :span="12" v-for="(file, index) in [...updateFile, ...form.files]" :key="file.uid">
                                <div class="mb-2 d-flex" style="border: 1px solid #dee3e7;" :class="(file.name === form.cover_image) ? 'mm-image_covered' : ''">
                                <div class="mm-image-container" @click="showImage(file)">
                                    <el-image v-if="['image/png', 'image/jpeg', 'jpg', 'jpeg', 'png'].includes(file.type || (/[^.]*$/.exec(file.name)[0]))"
                                    style="max-width:100%; max-height:100%; position: relative; top: 50%; transform: translateY(-50%);"
                                    class="p-1"
                                    :src="file.url"
                                    fit="fill">
                                    </el-image>
                                    <div v-else class="text-center">
                                    <i class="el-icon-document-copy mt-2" style="font-size: 50px;"></i>
                                    <span class="d-block mt-2 font-weight-bold" >{{/[^.]*$/.exec(file.name)[0]}}</span>
                                    </div>
                                </div>
                                <div class="ml-2" >
                                    <h3>{{file.name }}</h3>
                                    <div class="d-flex">
                                    <el-button v-if="['image/png', 'image/jpeg', 'jpg', 'jpeg', 'png'].includes(file.type || (/[^.]*$/.exec(file.name)[0]))"
                                        class="mr-3"
                                        @click="setCoverImage(file)"
                                        type="primary"  icon="el-icon-postcard" size="medium">
                                        {{ (file.name === form.cover_image) ? $t('message.remove_cover') : $t('message.set_cover') }}
                                    </el-button>
                                    <a :href="file.url" :download="file.name" class="mr-3">
                                        <el-button @click="downloadFile(file)" type="primary"  icon="el-icon-download" size="medium"></el-button>
                                    </a>
                                    <el-button @click="destroyFile(file, index, updateFile)" class="mr-3" type="danger"  icon="el-icon-delete-solid" size="medium">
                                    </el-button>
                                    </div>

                                </div>

                                </div>
                            </el-col>
                        </el-row>
                    </div>
                    <!-- end UPLOADED  add -->
                </div>

				<div class="my-new-card-style" v-if="form.rejected_comments && form.rejected_comments.length > 0">
					<div class="u-clearfix mt-2">
						<el-row :gutter="20" v-for="comment in form.rejected_comments" :key="'comment-'+comment.id" class="mb-2 p-2">
							<el-col :span="10" class="bg-sausage rounded-sm">
								{{ comment.rejected_comment }} 
								<div class="w-100 text-right">
									{{ comment.participant_name }}  {{ comment.created_at }}
								</div>
							</el-col>
							<el-col :span="4" class="text-center">
								<el-checkbox v-model="comment.fixed_status" :disabled="disabledComment(comment)" label="fixed" border @change="changeFixedStatus(comment)"></el-checkbox>
							</el-col>
							<el-col :span="10" class="bg-sausage">
								{{ comment.fixed_comment ? comment.fixed_comment : '' }}
								<div class="w-100 text-right rounded-sm">
									{{ comment.fixed_user_name ? comment.fixed_user_name : '' }}  {{ comment.fixed_user_id ? comment.updated_at : '' }}
								</div>
							</el-col>
						</el-row>
					</div>
				</div>

                <div class="my-new-card-style">
                    <div class="u-clearfix mt-2">
                        <div class="d-flex mb-3">
                            <div class="font-bold mt-3 mr-2"> {{ $t("message.check_list") }}</div>

                            <div class="button-style-my w-200" v-popover:myname.right >
                                {{ $t('message.add') }}
                            </div>
                            <popover name="myname"  v-model="addingTask" :width="300">
                                <div>
                                    <el-input
                                        ref="checkListCreate"
                                        maxlength="30"
                                        show-word-limit
                                        :autosize="{ minRows: 4}"
                                        :placeholder="$t('message.name')"
                                        v-model="newTask">
                                    </el-input>
                                    <div class="mt-2 text-right w-100">
                                    <el-button type="primary"  size="medium" @click="createCheckList(newTask)">{{ $t('message.save') }}</el-button>
                                    <el-button type="danger"  size="medium"  @click="addingTask = false">{{ $t('message.cancel') }}</el-button>
                                    </div>
                                </div>
                            </popover>


                            <!-- <el-popover
                                placement="left"
                                :title="$t('message.new_list')"
                                width="400"
                                trigger="hover"
                                v-model="addingTask">
                                <div>
                                    <el-input
                                        ref="checkListCreate"
                                        maxlength="30"
                                        show-word-limit
                                        :autosize="{ minRows: 4}"
                                        :placeholder="$t('message.name')"
                                        v-model="newTask">
                                    </el-input>
                                    <div class="mt-2 text-right w-100">
                                    <el-button type="primary"  size="medium" @click="createCheckList(newTask)">{{ $t('message.save') }}</el-button>
                                    <el-button type="danger"  size="medium"  @click="addingTask = false">{{ $t('message.cancel') }}</el-button>
                                    </div>
                                </div>

                                <el-button slot="reference" class="button-style-my" style="border-radius: 0px !important;" >
                                    Добавить
                                </el-button>
                            </el-popover> -->
                        </div>
                    </div>

                    <div class="task-check-tasks d-block w-100" v-for="(list, list_index) in form.checkLists" :key="list.number">
                        <div class="gc-card rounded-sm mb-4 p-relative p-5">

                            <!-- CheckList Name Update Delete  -->
                            <div class="mb-2 d-flex align-center">
                                <i class="el-icon-tickets mr-2"></i>
                                <div class="d-flex w-100">
                                    <div  v-if="list.updating"   class="w-100 mt-2 p-5 mm-updating-back-color">
                                        <!-- <el-input
                                            ref="checkListUpdate"
                                            type="textarea"
                                            :autosize="{ minRows: 2}"
                                            :placeholder="$t('message.please_input')"
                                            v-model="newTask">
                                        </el-input>
                                        aa -->
                                        <el-input
                                        ref="checkListUpdate"
                                        type="textarea"
                                        :autosize="{ minRows: 2}"
                                        :placeholder="$t('message.please_input')"
                                        v-model="newTask">
                                        </el-input>
                                        <div class="d-flex justify-content-between">
                                            <div class="text-left w-100 mt-2">
                                                <el-button type="primary"  size="medium" @click="saveCheckList(list)"> {{ $t('message.save') }}</el-button>
                                                <el-button type="info"  size="medium" @click="closeCheckList(list)"> {{ $t('message.cancel') }} </el-button>
                                            </div>
                                        </div>
                                    </div>
                                    <span v-if="!list.updating" class="font-bold pointer" @click="updateCheckList(list)" > {{ list.name }} </span>
                                    <div  v-if="!list.updating" class="ml-auto d-flex">
                                        <div
                                            class="change-form ml-auto pointer m-font12"
                                            ref="showChangeContentBtn"
                                            @click="updateCheckList(list)"
                                        >
                                            {{ $t("message.update") }}
                                        </div>
                                        <div
                                            class="change-form ml-3 pointer m-font12"
                                            ref="showChangeContentBtn"
                                            @click="deleteCheckList(list)"
                                        >
                                            {{ $t("message.delete") }}
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <!-- Checked Percentage -->
                            <div>
                                <el-progress
                                    class="li-color-progress"
                                    :text-inside="true"
                                    :stroke-width="18"
                                    :percentage="list.percentage"
                                    status="success"
                                ></el-progress>
                            </div>
                            <!-- Mini Tasks Start -->
                            <div v-for="(miniTask, index) in list.miniTasks" :key="miniTask.number" id="miniTaskId" @click="parentClicked($event, miniTask)" class="mm-hover-parent item-checkbox-task mt-2" >
                    <!-- Mini Tasks Checkbox Show -->
                    <div v-if="!miniTask.updating" class="checkbox-task">
                      <div>
                        <div class="mini-tasks-checkbox">
                            <input type="checkbox"
                             :id="list_index+'_miniTask_'+index"
                             @change="checkListPercentage(list.number)"
                             :name="list_index+'_miniTask_'+index"
                             v-model="miniTask.is_done">
                            <label :for="list_index+'_miniTask_'+index"
                            v-html="urlify(miniTask.text)"
                            :class="miniTask.is_done ? 'mm-line-through' : ''"
                            class="checkbox-text font-weight-300 ml-2 color_th m-font12" ></label>
                        </div>


                        <div v-if="miniTask.fileList.length > 0 || miniTask.users.length > 0 || miniTask.date.length > 0"
                          class="mm-mini-additional-info">
                            <span  class="font-weight-300 mr-1 font-s-11" v-for="user in users.filter(el => miniTask.users.includes(el.id))" :key="user.id" :class="miniTask.users.length > 0 ? 'mr-1' : ''">
                              <i  class="el-icon-user mr-1"></i>
                              {{ user.name }}
                            </span>
                            <span  class="font-weight-300 mr-1 font-s-11" v-for="file in miniTask.fileList" :key="file.uid" @click="showImage(file)" :class="miniTask.users.length > 0 ? 'mr-1' : ''">
                              <i class="el-icon-picture-outline pointer mr-1"></i>
                              <span class="pointer"> {{ file.name }}</span>
                            </span>
                            <span  class="font-weight-300 font-s-11"  v-if="(miniTask.date && miniTask.date.length > 0)"  :class="miniTask.users.length > 0 ? 'mr-1' : ''" >
                              <i class="el-icon-time"></i>
                              {{ (miniTask.date && miniTask.date.length > 0) ? miniTask.date[0] + ' / ' + miniTask.date[1] : '' }}
                            </span>
                        </div>

                      </div>
                    </div>

                    <!-- Mini Tasks Edit Buttons Start -->
                    <div class="checkbox-task-delet">
                      <div v-if="!miniTask.updating" class="mm-hover-show">
                        <el-popover
                          popper-class="ali-elp"
                          placement="top"
                          :title="$t('message.users')"
                          trigger="click">
                          <div class="mb-5">
                              <el-transfer
                                :titles="[$t('message.others'), $t('message.responsible')]"
                                :props="{key: 'id', label: 'name'}"
                                v-model="miniTask.users"
                                filterable
                                :filter-placeholder="$t('message.search')"
                                :data="JSON.parse(JSON.stringify(users))">
                              </el-transfer>
                          </div>
                          <i slot="reference" class="el-icon-user pointer update-i"></i>
                        </el-popover>

                        <el-popover
                          width="430"
                          :title="$t('message.attachments')"
                          placement="top"
                          @show="updateMiniTaskFiles(miniTask)"
                          trigger="click">
                          <div class="mb-5 w-100">
                            <el-upload
                              class="upload-demo"
                              action="/"
                              :accept="fileAccepts"
                              :auto-upload="false"
                              :on-change="updateFileInList"
                              :on-remove="removeUpdateFileFromList"
                              :on-preview="showImage"
                              :file-list="miniTask.fileList"
                              list-type="picture">
                              <el-button size="small" type="primary">Click to upload</el-button>
                            </el-upload>

                          </div>
                          <i slot="reference" class="el-icon-document-add pointer update-i"></i>
                        </el-popover>

                        <el-popover
                          placement="top"
                          :title="$t('message.deadline')"
                          trigger="click">
                          <div class="mb-5 w-100">
                            <el-date-picker
                              popper-class="mobil-el-date-picker"
                              v-model="miniTask.date"
                              type="datetimerange"
                              range-separator="/"
                              start-placeholder="Start date"
                              end-placeholder="End date"
                              format="yyyy-MM-dd HH:mm"
                              value-format="yyyy-MM-dd HH:mm"
                            >
                            </el-date-picker>
                          </div>
                          <i slot="reference" class="el-icon-time pointer update-i"></i>
                        </el-popover>

                        <el-popover
                          placement="top"
                          width="500"
                          :title="$t('message.comment')"
                          @after-enter="miniCommentScrollAndFocus(index)"
                          trigger="click">
                          <div class="w-100">
                            <div :id="'miniTaskCommentContainer_'+index" class="mm-mini-task-comment w-100">
                              <div v-for="(min_comment, com_index) in miniTask.comments" :key="'com_'+com_index">
                                <div class="mm-mini-comment-container"
                                  :class="[(authUser.id === min_comment.user_id) ? ['mm-left-15', 'darker'] : 'mm-right-15', (miniTaskNewComment.index === com_index) ? 'mm-back-editing' : '' ] "
                                  >
                                  <div v-if="authUser.id === min_comment.user_id" @click="editMiniTaskComment(min_comment, com_index)" class="mm-mini-comment-edit-icon">
                                    <i class="el-icon-edit-outline"></i>
                                  </div>
                                  <span class="d-block">{{ min_comment.comment }}</span>
                                  <span class="font-s-11 mt-2" :class="(authUser.id === min_comment.user_id) ? 'time-left' : 'time-right'">
                                    <span v-if="users.find(el => el.id === min_comment.user_id)" class="mr-4">
                                      <i class="el-icon-user"></i> {{ users.find(el => el.id === min_comment.user_id).name + ', ' }}
                                    </span>
                                    <span>
                                      {{ min_comment.updated_at }}
                                    </span>
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div class="d-flex">
                              <el-input
                                ref="MiniTaskNewCommentRef"
                                class="mt-2"
                                autosize
                                type="textarea"
                                :placeholder="$t('message.please_input')"
                                v-model="miniTaskNewComment.comment">
                              </el-input>
                              <i @click="saveMiniTaskComment(miniTask, index)" class="el-icon-s-promotion mm-send-icon"></i>
                              <i v-if="miniTaskNewComment.index >= 0" @click="cancelSavingMiniTaskComment(miniTask)" class="el-icon-close mm-send-icon"></i>
                            </div>
                          </div>
                          <el-badge slot="reference" is-dot :hidden="(!miniTask.comments || miniTask.comments.length <= 0)">
                            <i class="el-icon-s-comment pointer update-i"></i>
                          </el-badge>
                        </el-popover>

                        <i class="el-icon-edit-outline update-i" @click="updateMiniTask(miniTask)"></i>
                        <i class="el-icon-delete" @click="deleteMiniTask(list, index)"></i>
                      </div>
                    </div>
                    <!-- Mini Tasks Edit Buttons End -->

                    <!-- Update Mini Tasks Text -->
                    <div v-if="miniTask.updating" class="w-100 mt-2 p-5 mm-updating-back-color">
                      <el-input
                        ref="miniTaskInput"
                        type="textarea"
                        :autosize="{ minRows: 2}"
                        :placeholder="$t('message.please_input')"
                        v-model="newMiniTask.text">
                      </el-input>
                      <div class="d-flex justify-content-between">
                        <div class="text-left w-100 mt-2">
                          <el-button type="primary"  size="medium" @click="updateCurrentMiniTask(miniTask)"> {{ $t('message.save') }}</el-button>
                          <el-button type="info"  size="medium" @click="closeUpdateMiniTask(miniTask)">{{ $t('message.cancel') }}</el-button>
                        </div>
                      </div>
                    </div>
                  </div>
                            <!-- Mini Tasks End -->

                            <!-- Start Add Mini Task Component -->
                            <div v-if="list.addingMiniTask" class="w-100 mt-2 p-5 mm-updating-back-color">
                            <el-input
                                ref="miniTaskInput"
                                type="textarea"
                                :autosize="{ minRows: 2}"
                                :placeholder="$t('message.please_input')"
                                v-model="newMiniTask.text">
                            </el-input>
                            <div class="d-flex justify-content-between">
                                <div class="text-left w-100 mt-2">
                                <el-button type="primary" size="mini" @click="updateMiniTaskList(list)"> {{ $t('message.save') }}</el-button>
                                <el-button type="info" size="mini" @click="list.addingMiniTask = false">{{ $t('message.cancel') }}</el-button>
                                </div>
                                <div class="text-right mt-2 w-100">
                                    <el-popover
                                    popper-class="ali-elp"
                                    placement="top"
                                    :title="$t('message.users')"
                                    trigger="click">
                                    <div class="mb-5">
                                        <el-transfer
                                        :titles="[$t('message.others'), $t('message.responsible')]"
                                        :props="{key: 'id', label: 'name'}"
                                        v-model="newMiniTask.users"
                                        filterable
                                        :filter-placeholder="$t('message.search')"
                                        :data="JSON.parse(JSON.stringify(users))">
                                        </el-transfer>
                                    </div>
                                    <el-button class="ml-2" slot="reference" type="success" size="mini" icon="el-icon-user"> <i class="el-icon-plus"></i> </el-button>
                                    </el-popover>

                                    <el-popover
                                    width="430"
                                    :title="$t('message.attachments')"
                                    placement="top"
                                    trigger="click">
                                    <div class="mb-5 w-100">
                                        <el-upload
                                        class="upload-demo"
                                        action="/"
                                        :accept="fileAccepts"
                                        :on-change="setFileInList"
                                        :on-remove="removeFileFromList"
                                        :on-preview="showImage"
                                        :auto-upload="false"
                                        :file-list="[]"
                                        list-type="picture">
                                        <el-button size="small" type="primary">Click to upload</el-button>
                                        </el-upload>

                                    </div>
                                    <el-button class="ml-2" slot="reference" type="primary" size="mini" icon="el-icon-document-add"> {{$t('message.file')}} </el-button>
                                    </el-popover>

                                    <el-popover
                                    placement="top"
                                    :title="$t('message.deadline')"
                                    trigger="click">
                                    <div class="mb-5 w-100">
                                        <el-date-picker
                                        popper-class="mobil-el-date-picker"
                                        v-model="newMiniTask.date"
                                        type="datetimerange"
                                        range-separator="/"
                                        start-placeholder="Start date"
                                        end-placeholder="End date"
                                        format="yyyy-MM-dd HH:mm"
                                        value-format="yyyy-MM-dd HH:mm"
                                        >
                                        </el-date-picker>
                                    </div>
                                    <el-button class="ml-2" slot="reference"  type="info" size="mini" icon="el-icon-time">{{$t('message.date')}}</el-button>
                                    </el-popover>
                                </div>
                            </div>
                            </div>
                            <!-- End Add Mini Task Component -->

                            <!-- Add Mini Task Button -->
                            <div v-if="!list.addingMiniTask" class="text-right">
                            <el-button
                                @click="addMiniTask(list)"
                                class="mt-2 ml-2 "
                                type="primary"
                                size="mini"
                                icon="el-icon-s-help">
                                {{ $t("message.add_item") }}
                            </el-button>
                            </div>

                        </div>
                    </div>
                </div>

                <el-tabs v-model="activeName" class="w-100">
                    <el-tab-pane label="Сообщения" name="first">
                        <div class="task-check-tasks d-block w-100">
                            <div class="gc-card rounded-sm mb-4 p-relative p-2">
                                <span class="font-bold mb-2 d-block">
                                    <i class="el-icon-notebook-2"></i>  {{ $t("message.message_m") }}
                                </span>
                                <div class="w-100">
                                    <div class="mm-mini-task-comment w-100">
                                    <div v-for="(comment, index) in form.comments" :key="'com_'+index">
                                        <div class="mm-mini-comment-container"
                                        :class="[((authUser.id === comment.user_id) ? ['mm-left-15', 'darker'] : 'mm-right-15'), (new_form_comment.index === index) ? 'mm-back-editing' : '' ]">
                                        <div v-if="authUser.id === comment.user_id" @click="editFormComment(comment, index)" class="mm-mini-comment-edit-icon">
                                            <i class="el-icon-edit-outline"></i>
                                        </div>
                                        <span class="d-block">{{ comment.comment }}</span>
                                        <span class="font-s-11 mt-2" :class="(authUser.id === comment.user_id) ? 'time-left' : 'time-right'">
                                            <span v-if="users.find(el => el.id === comment.user_id)" class="mr-4">
                                            <i class="el-icon-user"></i> {{ users.find(el => el.id === comment.user_id).name + ', ' }}
                                            </span>
                                            <span>
                                            {{ comment.updated_at }}
                                            </span>
                                        </span>
                                        </div>
                                    </div>
                                    </div>
                                    <div class="d-flex">
                                    <el-input
                                        ref="FormCommentRef"
                                        class="mt-2"
                                        :autosize="{ minRows: 2, maxRows: 4}"
                                        type="textarea"
                                        :placeholder="$t('message.please_input')"
                                        v-model="new_form_comment.comment">
                                    </el-input>
                                    <i @click="saveFormComment()" class="el-icon-s-promotion mm-send-icon"></i>
                                    <i v-if="new_form_comment.index >= 0" @click="cancelSavingFormComment()" class="el-icon-close mm-send-icon"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane label="Сделка" name="second">
                        <div class="gc-card rounded-sm mb-4 p-relative p-2">
                            <span class="font-bold mb-2 d-block">
                                <i class="el-icon-notebook-2"></i>  {{$t('message.deal')}}
                            </span>

                            <el-row :gutter="20">
                                <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="8">
                                    <el-form-item :label="$t('message.company')" class="label_mini mb-0">
                                        <div class="d-flex">
                                            <select-company
                                                :size="'large'"
                                                :placeholder="$t('message.company')"
                                                :id="company_id"
                                                v-model="company_id"
                                                class="w-100"
                                            >
                                            </select-company>
                                            <!-- <i @click="drawerCreateCompany = true" class="el-icon-circle-plus-outline task-select-plus-icon" ></i> -->
                                            <el-button :size="'large'" class="ml-2" type="primary" icon="el-icon-plus" @click="drawerCreateCompany = true"></el-button>
                                        </div>
                                    </el-form-item>
                                </el-col>

                                <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="8">
                                    <el-form-item :label="$t('message.client')" class="label_mini mb-0">
                                        <div class="d-flex">
                                            <select-client
                                            :size="'large'"
                                            :placeholder="$t('message.client')"
                                            :id="client_id"
                                            :company_id="company_id"
                                            v-model="client_id"
                                            class="w-100"
                                            >
                                            </select-client>
                                            <!-- <i @click="drawerCreateClient = true" class="el-icon-circle-plus-outline task-select-plus-icon"></i> -->
                                            <el-button :size="'large'" class="ml-2" type="primary" icon="el-icon-plus" @click="drawerCreateClient = true"></el-button>
                                        </div>
                                    </el-form-item>
                                </el-col>

                                <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="8">
                                    <el-form-item :label="$t('message.deal')" class="label_mini mb-0" prop="deal_id">
                                        <div class="d-flex">
                                            <select-deal
                                                :size="'large'"
                                                :placeholder="columns.deal_id.title"
                                                :id="form.deal_id"
                                                v-model="form.deal_id"
                                                :client_id="client_id"
                                                :company_id="company_id"
                                                class="w-100"
                                                >
                                                </select-deal>
                                            <!-- <i @click="drawerCreateDeal = true" class="el-icon-circle-plus-outline task-select-plus-icon"></i> -->
                                            <el-button :size="'large'" class="ml-2" type="primary" icon="el-icon-plus" @click="drawerCreateDeal = true"></el-button>
                                        </div>
                                    </el-form-item>
                                </el-col>
                            </el-row>

                        </div>
                    </el-tab-pane>
                    <el-tab-pane label="Выставка" name="third">
                        <div class="gc-card rounded-sm mb-4 p-relative p-2">
                            <span class="font-bold mb-2 d-block">
                                <i class="el-icon-notebook-2"></i>  {{$t('message.exhibition')}}
                            </span>
                            <el-form-item  prop="exhibition_id" class="label_mini mb-0">
                                <select-exhibition
                                    :size="'medium'"
                                    :placeholder="$t('message.exhibition')"
                                    :id="form.exhibition_id"
                                    v-model="form.exhibition_id"
                                >
                                </select-exhibition>
                            </el-form-item>
                        </div>
                    </el-tab-pane>
                </el-tabs>
            </el-form>
      </div>
    </div>

    <div class="app-modal app-modal__full modal-color-bg">
        <el-dialog
          custom-class="modal-dialog-s-w-a"
          class="add-participants"
          :class="[ leftVersion ? (mode ? 'l-modal-style' : 'd-modal-style') : '']"

          :title="$t('message.labels')"
          :visible.sync="labels"
          width="35%"
          :append-to-body="true"
          ref="drawerLabels"
          @opened="drawerOpened('drawerLabelsChild')"
          @closed="drawerClosed('drawerLabelsChild')"
        >
          <Tags
            ref="drawerLabelsChild"
            drawer="drawerLabels"
            :selectedTaskLabels="form.labels"
            @getTaskLabels="updateTaskLabels"
            > </Tags>
        </el-dialog>
        <!-- end metka -->

        <el-dialog
          v-if="selectedImage.name"
          :title="selectedImage.name"
          :append-to-body="true"
          width="85%"
          custom-class="mm-picture-show"
          :visible.sync="dialogPictureVisible">
          <div ref="ImageContainer" class="text-center w-100">
            <el-image v-if="['image/png', 'image/jpeg', 'jpg', 'jpeg', 'png'].includes(selectedImage.type || (/[^.]*$/.exec(selectedImage.name)[0])) ||
                (selectedImage.raw && ['image/png', 'image/jpeg'].includes(selectedImage.raw.type))"
              style="object-fit: contain;"
              class="p-1"
              :src="selectedImage.url"
              fit="fill">
            </el-image>
          </div>

        </el-dialog>
        <el-drawer
        :class="[ leftVersion ? (mode ? 'l-modal-style' : 'd-modal-style') : '']"
          :with-header="false"
          :visible.sync="drawerCreateCompany"
          ref="drawerCreateCompany"
          size="70%"
          :append-to-body="true"
          @opened="drawerOpened('drawerCreateCompanyChild')"
          @closed="drawerClosed('drawerCreateCompanyChild')"
        >
          <div>
            <create-company ref="drawerCreateCompanyChild" drawer="drawerCreateCompany">
            </create-company>
          </div>
        </el-drawer>

        <el-drawer
          :with-header="false"
          :visible.sync="drawerCreateClient"
          ref="drawerCreateClient"
          size="70%"
          :append-to-body="true"
          class="body_scroll_70"
          @opened="drawerOpened('drawerCreateClientChild')"
          @closed="drawerClosed('drawerCreateClientChild')"
        >
            <div>
                <create-client
                    ref="drawerCreateClientChild"
                    drawer="drawerCreateClient"
                >
                </create-client>
            </div>
        </el-drawer>

        <el-drawer
        :class="[ leftVersion ? (mode ? 'l-modal-style' : 'd-modal-style') : '']"
          size="80%"
          :append-to-body="true"
          class="body_scroll_80 mt-00"
          :visible.sync="drawerCreateDeal"
          :fullscreen="true"
          ref="drawerDealCreate"
          custom-class="ge"
          @opened="drawerOpened('drawerDealCreateChild')"
          @closed="drawerClosed('drawerDealCreateChild')"
          >
          <div
              @click="closeAddModel()"
              class="
              close-modal-stick
              d-flex
              align-center
              p-fixed
              text-white
              p-2
              font-bold
              pointer
              transition
              "
          >
              <div class="close-modal-icon text-center rounded-circle transition">
              <i class="el-icon-close"></i>
              </div>
              <span class="close-modal-text text-uppercase ml-2"></span>
          </div>
          <DealCreateForTask
              ref="drawerDealCreateChild"
              :dealCreate="dealCreate"
              drawer="drawerDealCreate"
          />
        </el-drawer>

        <el-dialog
          class="KP_modal"
          :title="$t('message.delete')"
          :visible.sync="drawerDeleteTask"
          :append-to-body="true"
          width="30%"
          center
          ref="drawerDeleteTask"
          @opened="drawerOpened('deleteTaskComponent')"
          @closed="drawerClosed('deleteTaskComponent')"
        >

          <delete-task
            ref="TaskOfferComponent"
            :task_id="form.id"
            @deleteTaskClose="deleteTaskClose"
            drawer="drawerTaskOfferCreate"
          >
          </delete-task>
        </el-dialog>

		<!-- REJECTED TASK COMMENT -->
		<el-dialog title="Rejected Task Comment" :visible.sync="dialogTaskComment" append-to-body @closed="closedTaskComment">
			<div class="app-form__group mb-4">
				<span class="input--label d-block mb-2"> Comment </span>
				<el-input
					type="textarea"
					placeholder="Comment"
					v-model="rejected_form.comment"
					autocomplete="off"
				>
				</el-input>
            </div>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="saveRejectedTaskComment"> Save </el-button>
			</span>
		</el-dialog>
		<!-- END REJECTED TASK COMMENT -->
    </div>
  </div>
</template>
<script>
import Tags from "./tags";
import selectDeal from "@/components/inventory/select-deal";
import selectCompany from "@/components/inventory/select-company";
import selectClient from "@/components/selects/select-client";
import CreateCompany from "@/views/companies/components/crm-create";
import CreateClient from "@/views/clients/components/crm-create";
import DealCreateForTask from "@/views/deals/components/add-modal";
import selectExhibition from "@/components/inventory/select-exhibition";
import selectBoard from "@/components/inventory/select-task-board";
import selectPriority from "@/components/inventory/select-priority";
import selectProjects from '@/components/inventory/select-projects';

import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";
import show from "@/utils/mixins/show";
import task from "@/utils/mixins/views/task";

export default {
  name: 'task_modal',
  mixins: [form, drawer, task, show],
  components: {
    Tags,
    selectDeal,
    selectCompany,
    selectClient,
    CreateCompany,
    CreateClient,
    DealCreateForTask,
    selectExhibition,
    selectBoard,
    selectPriority,
    selectProjects
  },
  props: {
    board_id:{
      default: null
    },
    selected_deal:{
      default: Object
    }
  },
  data() {
    return {
		rejected_form: {
			id: null,
			participant_id: null,
			task_id: null,
			fixed_status: false,
			comment: '',
		},
		dialogTaskComment: false,
		task_checked: false,
		checkedAll: [],
		participants: [],
      	visible2: false,
		cities: [],
		activeName: 'first',
		drawerDeleteTask: false,
		taskToChecking: false,
		drawerCreateCompany: false,
		drawerCreateClient: false,
		drawerCreateDeal: false,
		dealCreate: true,
		comment: "",
		company_id: null,
		client_id: null,
		labels: false,
		checkListForMiniTask: null,
		deal_id: null,
		finishDateCheck: false,
		addingTask: false,
		fileList: [],
		updateFile: [],
		taskDates: [],
		taskPercentage: 0,
		miniTasksCount: 0,
		fileAccepts: "image/png, image/jpeg, application/pdf, .doc,.docx,application/msword,.xls,.xlsx,application/vnd.openxmlformats-officedocument.wordprocessingml.document, text/plain,",
    };
  },
  computed: {
      ...mapGetters({
          users: "users/inventory",
          authUser: "auth/user",
          rules: "tasks/rules",
          model: "tasks/model",
          columns: "tasks/columns",
          mode: "MODE"
      }),
      leftVersion () {
        return this.$store.getters.LEFTVERSION
      },
	  active_participants(){
		return this.form.participants.filter(user => user.permission);
	  }
  },
  watch: {
      finishDateCheck(val) {
        if(val){
          if(this.finish_date){
            this.form.finish_date = this.finish_date;
          }else{
             this.form.finish_date = this.formatDate(new Date());
          }
        }else{
          this.form.finish_date = ''
        }
      },
      form: {
          handler: async function(newVal, oldVal) {
              this.taskPercentage = 0;
              let completed = 0;
              let uncompleted = 0;
              this.form.checkLists.forEach(check_list => {
                check_list.miniTasks.forEach(mini_task => {
                  if(mini_task.is_done){
                    completed += 1
                  }else{
                    uncompleted += 1
                  }
                });
              });
              this.miniTasksCount = completed + uncompleted;
              if((completed + uncompleted) === 0){
                this.taskPercentage = 0;
              }else{
                this.taskPercentage = 100*completed/(completed + uncompleted);
              }
              if(this.taskPercentage === 100 && this.miniTasksCount > 0){
                this.form.status = 4;
              }
              if(this.taskPercentage < 100 && this.miniTasksCount > 0){
                this.form.status = 1;
              }
              if(this.form.status === 4){
                this.taskToChecking = true;
              }
          },
          deep: true,
          immediate: true
      },
      taskToChecking: {
          handler: async function(newVal, oldVal) {
             if(newVal){
               this.form.status = 4;
             }else{
               this.finishDateCheck = false;
               this.form.status = 1;
             }
          },
          deep: true,
          immediate: true
      },
  },
  methods: {
    ...mapActions({
        updateUsers: "users/inventory",
        save: "tasks/update",
        show: "tasks/show",
        saveFiles: "tasks/storeFiles",
        empty: "tasks/empty",
        downloadFileFromStorage: "tasks/downloadFile",
        deleteFile: "tasks/deleteFile",
        archivateTask: "tasks/archivateTask",
        updateDealTasks: "deals/dealTasks",
        updateDealsList: "boards/index",
		rejectedTaskCommentStore: "taskComment/rejectedTaskCommentStore",
    }),

    destroyFile(file, index, fileList){
      if(file.id){
        this.deleteFile({task_id: this.form.id, file: file.name})
          .then(res => {
            this.form.files = this.form.files.filter(item => item.id != file.id);
          });
      }else{
        fileList.splice(index, 1);
      }
      if(file.name === this.form.cover_image){
        this.form.cover_image = '';
      }
    },

    async fetchData() {
      if (!this.loadingData) {
          this.loadingData = true;
          await this.updateUsers();
          await this.show(this.selectedItem.id).then(res => {
              this.form.checkLists.map((list, index) => {
                  list.number = index + 1;
                  let checked = list.miniTasks.filter(item => item.is_done === true).length;
                  let count = list.miniTasks.length;
                  if(count > 0){
                    list.percentage = Math.round(checked * 100/count);
                  }else{
                    list.percentage = 0;
                  }
                });

              this.form.checkLists.map((list, index) => list.miniTasks.map((task ,key) => {
                task.checkListNumber = list.number;
                task.number = key + 1;
              }));
              if(this.form.finish_date){
                this.finish_date = this.form.finish_date;
                this.finishDateCheck = true;
              }
              this.form.comments.map((item, index) => item.number = index + 1);
              this.company_id = this.form.company_id;
              this.client_id = this.form.client_id;
              this.taskDates.push(this.form.begin_date);
              this.taskDates.push(this.form.end_date);

			if (!_.isEmpty(this.form.participants)) {
				let user_ids = _.map(this.form.participants, 'user_id');
				this.participants = user_ids;
				this.checkedAll = this.active_participants.map(item => item.task_checked);
				console.log('checkedAll', this.checkedAll);
			}
			console.log('authuser', this.authUser);
              this.loadingData = false;
          }).catch(err => {
              this.loadingData = false;
          });
      }
    },
    archivatingTask(task_id) {
      this.$confirm(
          this.$t('message.do_you_really_want_to_do_this'),
          this.$t('message.warning'), {
            confirmButtonText: this.$t('message.yes'),
            cancelButtonText: this.$t('message.no'),
            type: "warning"
          }
        )
        .then(() => {
          this.archivateTask(task_id).then(() => {
          this.close(true);
          this.parent().listChanged();

          });
          this.$message({
            type: "success",
            message: this.$t('message.operation_completed')
          });
        })
        .catch(() => {
          this.$message({
            type: "warning",
            message: this.$t('message.operation_canceled')
          });
        });
    },
    deleteTaskClose(val) {
      this.drawerDeleteTask = false;
      if(val){
        this.close();
        this.parent().listChanged();
      }
    },
	changeTaskChecked(task_checked, user_id){
		let user = _.find(this.form.participants, {'user_id': user_id});
		this.$set(user, 'task_checked', task_checked);
	},
	closedTaskComment(){
		this.rejected_form.comment = '';
		this.form.rejected_comments.forEach(item => {
			if (!item.fixed_user_id) {
				item.fixed_status = false;
			}
		});
	},
	saveRejectedTaskComment(close = true){
		this.rejected_form.participant_id = this.authUser.id;
		this.rejected_form.task_id = this.form.id;
		
		this.rejectedTaskCommentStore(this.rejected_form).then((res) => {
			let data = res.data.result.data.rejected_task_comment;
			this.dialogTaskComment = false;
			this.$alert(res);
			if (!data.fixed_status) {
				this.form.rejected_comments.push(data);
			}
			else {
				let comment = this.form.rejected_comments.find(item => item.id == data.id);
				this.$set(comment, 'fixed_status', data.fixed_status);
				this.$set(comment, 'fixed_user_name', data.fixed_user_name);
				this.$set(comment, 'fixed_user_id', data.fixed_user_id);
				this.$set(comment, 'fixed_comment', data.fixed_comment);
				this.$set(comment, 'updated_at', data.updated_at);
			}
			
		}).catch((err) => {
			console.log('err', err);
		});
	},
	changeFixedStatus(comment){
		if (comment.fixed_status) {
			this.rejected_form.id = comment.id;
			this.rejected_form.fixed_status = comment.fixed_status;
			this.dialogTaskComment = true;
		}
	},
	disabledComment(comment){
		if (comment.fixed_status && comment.fixed_user_id) {
			return true;
		}
		return false;
	},
  },
};
</script>
<style>
  .task-select-plus-icon {
    font-size: 34px;
    margin: 2px 10px
  }
  .task_in_process_1 {
    background-color: rgb(255, 102, 0);
    color: white;
  }
  .task_for_check {
    background-color: rgb(50, 127, 226);
    color: white;

  }
  .task_done {
    background-color: rgb(92, 196, 92);
    color: white;
  }
  .wu-100 .el-upload{
    width: 100%;
  }
  .text-contents{
    min-height: 33px;
    display: block;
    resize: vertical;
    padding: 5px 15px;
    line-height: 1.5;
    box-sizing: border-box;
    width: 100%;
    font-size: inherit;
    color: #606266;
    background-color: #FFF;
    background-image: none;
    border: 1px solid #DCDFE6;
    border-radius: 4px;
    transition: border-color .2s cubic-bezier(.645,.045,.355,1);
  }
  .big-text-contents{
    display: flex;
    align-items: center;
    margin: 0 0 20px 6px;
  }
    .big-text-contents div i{
      font-size: 24px;
      padding: 5px;
    }
    .checkbox-task-delet .update-i, .update-i {
      color: #0a68c0;
    }
    .el-icon-delete, .checkbox-task-delet .el-icon-delete {
      color: #F56C6C;
    }
    .el-icon-edit-outline:hover, .el-icon-delete:hover {
      cursor: pointer;
    }
    /* .mm-updating-back-color{
      background-color: #dee3e7;
    } */
    .mm-line-through{
      text-decoration: line-through;
    }
    .mm-hover-show {
      display: none;
    }
    .mm-hover-parent{
      position: relative;
    }
    .mm-hover-parent:hover .mm-hover-show {
      position: absolute;
      top: 0;
      right: 0;
      display: flex;
      padding: 3px;
      /* background: rgba(255, 255, 255, 0.9); */
    }
    .mm-image-container{
      width: 100px;
      height: 100px;
      background-color: #dee3e7;
      display: inline-block;
    }
    .mm-mini-additional-info{
      width: 100%;
      font-size: 15px;
      padding: 4px;
      flex-direction: row;
      flex-wrap: wrap
    }
    .mm-image_covered {
      background-color: #fdceb3;
    }
    /* Chat containers */
    .mm-mini-comment-container {
      position: relative;
      /* border: 2px solid #dedede;
      background-color: #f1f1f1; */
      border-radius: 5px;
      padding: 3px 10px;
      margin: 10px 0;
    }

    /* Darker chat container */
    .darker {
      border-color: #ccc;
      background-color: #aebdc9;
    }

    /* Clear floats */
    .mm-mini-comment-container::after {
      content: "";
      clear: both;
      display: table;
    }

    /* Style time text */
    .time-right {
      float: right;
      color: #aaa;
    }

    /* Style time text */
    .time-left {
      float: left;
      color: #797676;
    }
    /* .mm-right-15 {
      margin-right: 15%;
    } */
    .mm-left-15 {
      margin-left: 15%;
    }
    .mm-send-icon {
      font-size: 22px;
      cursor: pointer;
      margin-left: 10px;
      padding: 4px;
      border: 1px solid grey;
      border-radius: 15%;
      align-self: flex-end;
    }
    .mm-mini-comment-edit-icon {
      display: none;
    }

    .mm-mini-comment-container:hover .mm-mini-comment-edit-icon {
      position: absolute;
      font-size: 22px;
      top: 0;
      right: 0;
      display: flex;
      padding: 3px;
      color: #0a68c0;
      background-color: rgba(255, 255, 255, 0.7);
    }
    .mm-mini-task-comment {
      max-height: 400px;
      overflow-y: scroll;
    }
    .mm-back-editing {
      background-color: #fc9744;
    }
</style>
