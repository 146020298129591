<template>
    <div style="height: 428px">
        <div v-show="this.dealTypesChart && this.dealTypesChart.length > 0" id="chart">
            <v-chart class="e-chart" :option="option" autoresize />
        </div>
        <div v-show="this.dealTypesChart && this.dealTypesChart.length === 0" class="eror">
            <img src="/img/dashboard.png" alt="Logo" class="d-block" />
            <h1>
                {{ $t("message.no_data_yet") }}
            </h1>
        </div>
    </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { formatMoney } from "@/filters/index";
import * as echarts from 'echarts/core';
import { TooltipComponent, LegendComponent } from 'echarts/components';
import { PieChart } from 'echarts/charts';
import { LabelLayout } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';
import VChart, { THEME_KEY } from "vue-echarts";

echarts.use([
    TooltipComponent,
    LegendComponent,
    PieChart,
    CanvasRenderer,
    LabelLayout
]);

export default {
    components: { VChart },
    provide() {
        return {
            [THEME_KEY]: "dark"
        };
    },
    props: {
        period: {
            default: 'weekly'
        },
        filterForm: {
            default: null
        },
        size: {
            default: '395'
        }
    },

    data() {
        return {
            chartClone: {},
            dealTypesData: {
                series: [],

                chartOptions: {
                    labels: [],
                    chart: {
                        type: 'donut',
                        foreColor: '#fff'
                    },
                    plotOptions: {
                        pie: {
                            donut: {
                                labels: {
                                    show: true,
                                    name: {
                                        show: true,
                                        fontSize: '15px',
                                        color: '#fff'
                                    },
                                    value: {
                                        show: true,
                                        fontSize: '15px',
                                        color: '#fff',
                                    },
                                    total: {
                                        show: true,
                                        fontSize: '15px',
                                        color: '#fff',
                                        label: 'overall'
                                    }
                                }
                            }
                        },
                    },
                    legend: {
                        show: true,
                        bottom: 'bottom',
                        left: 'center',
                        fontSize: '13px',
                        labels: {
                            colors: '#fff',
                            useSeriesColors: false
                        },
                        markers: {
                            strokeColor: '#fff',
                        },
                    },
                },
            },


            option: {
                backgroundColor: "transparent",
                tooltip: {
                    trigger: 'item'
                },
                legend: {
                    top: 'bottom',
                    left: 'center'
                },
                series: [
                    {
                        name: 'Title',
                        type: 'pie',
                        radius: ['40%', '70%'],
                        avoidLabelOverlap: false,
                        label: {
                            show: false,
                            position: 'center',

                        },
                        emphasis: {
                            label: {
                                show: true,
                                fontSize: 15,
                                fontWeight: 'bold',
                                formatter: function (evt) {
                                    return `${evt.name}: ${evt.value}`
                                },
                            }
                        },
                        labelLine: {
                            show: false
                        },
                        data: []
                    }
                ]
            }
        }
    },
    watch: {
        watchAll: {
            handler: async function (val) {
                setTimeout(() => {
                    this.updateChartData({ period: this.period, filter: this.filterForm });
                }, 500);
            },
            deep: true,
            immediate: true
        },
    },
    computed: {
        ...mapGetters({
            dealTypesChart: "home/dealTypesChart"
        }),
        watchAll() {
            return (this.period + JSON.stringify(this.filterForm));
        },
    },

    created() {
        this.chartClone = JSON.parse(JSON.stringify(this.dealTypesData));
    },
    methods: {
        ...mapActions({
            updateDealTypesChart: "home/dealTypesChart"
        }),

        updateChartData(data) {

            this.updateDealTypesChart(data)
                .then(() => {
                    if (this.dealTypesChart.length > 0) {
                        this.dealTypesData.series = JSON.parse(JSON.stringify(this.dealTypesChart.map(item => parseInt(item.money_amount))));
                        let self = this;
                        let counts = JSON.parse(JSON.stringify(this.dealTypesChart.map(item => item.deal_count).reduce((a, b) => parseInt(a) + parseInt(b), 0)));
                        this.dealTypesData = {
                            ...this.dealTypesData, ...{

                                chartOptions: {
                                    labels: JSON.parse(JSON.stringify(this.dealTypesChart.map(item => item.name))),
                                    legend: {
                                        show: true,
                                        fontSize: '13px',
                                        labels: {
                                            colors: '#fff',
                                            useSeriesColors: false
                                        },
                                        markers: {
                                            strokeColor: '#fff',
                                        },
                                    },
                                    plotOptions: {
                                        pie: {
                                            donut: {
                                                labels: {
                                                    name: {
                                                        formatter: function (name) {
                                                            if (self.dealTypesChart.some(item => item.name === name)) {
                                                                let text = name + ': ' + self.dealTypesChart.find(item => item.name === name).deal_count;
                                                                return text;
                                                            } else {

                                                                return name
                                                            }
                                                        },
                                                    },
                                                    value: {
                                                        formatter: function (val) {
                                                            return (self.$t('message.money_amount') + ': ' + formatMoney(val, 2));
                                                        },
                                                    },
                                                    total: {
                                                        show: (self.dealTypesChart.length > 1 ? true : false),
                                                        label: (self.$t('message.overall') + ': ' + counts),
                                                        formatter: function (w) {
                                                            let money_amount = self.dealTypesChart.map(item => item.money_amount).reduce((a, b) => parseInt(a) + parseInt(b), 0);
                                                            return self.$t('message.money_amount') + ': ' + formatMoney(money_amount, 2);
                                                        },
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        // Echart
                        this.option.series[0]['data'] = []
                        this.dealTypesChart.forEach(item => {
                            this.option.series[0]['data'].push({
                                name: item.name,
                                value: item.money_amount
                            })
                        })
                    } else {
                        this.dealTypesData = JSON.parse(JSON.stringify(this.chartClone));
                    }
                }).catch(err => console.log(err, 'errrrrrrr'));
        },
    },
};
</script>
