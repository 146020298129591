<template>
  <!-- <el-tab-pane label="Товары" name="second"> -->
  <div class="product-add s w-100">
    <div v-if="!change_in_deal_modal">
      <el-form ref="form" :label-position="'left'" :model="form" :rules="rules">
        <el-row  :gutter="20">
          <el-col  :span="24" style="text-align: right;">
            <el-form-item
                :label="$t('message.columns')"
                prop="columns"
              >
              
              <el-dropdown style="display:block" class="setting-cheek" :hide-on-click="false">
                  <el-button
                      class="padding_none"
                      size="small"
                      icon="el-icon-open"
                  ></el-button>
                  <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item
                          v-for="(column, index) in columns"
                          :key="'drop' + index"
                      >
                          <el-checkbox
                              :checked="column.show"
                              @change="check(column.column, $event)"
                              >{{ column.title }}
                          </el-checkbox>
                      </el-dropdown-item>
                  </el-dropdown-menu>
              </el-dropdown>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>

    <el-row v-loading="loadingData">    
        <el-col :span="24" class="mt-2">
          <el-table 
            :data="dealProductsList" 
            show-summary 
            :row-class-name="tableRowClassName"
            :summary-method="getSummaries" 
            border 
            style="width: 100%">
            <el-table-column label="Name" width="180" v-if="columns.name.show">
              <template slot-scope="scope">
                {{ scope.row.product.name }}
              </template>
            </el-table-column>
            <el-table-column label="Img" width="70" v-if="columns.name.show">
                <el-image 
                style="width: 49px; height: 49px"
                :src="url" 
                :preview-src-list="srcList">
                </el-image>
            </el-table-column>
            <el-table-column :label="columns.quantity.title" v-if="columns.quantity.show">
              <template slot-scope="scope">
                <el-input
                  :min="0"
                  type="number"
                  size="mini"
                  v-model="scope.row.quantity"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column :label="columns.measurement_id.title" width="60" v-if="columns.measurement_id.show">
              <template slot-scope="scope">
                {{ scope.row.product.measurement.name }}
              </template>
            </el-table-column>
            <el-table-column :label="columns.price.title" v-if="columns.price.show">
              <template slot-scope="scope">
                <el-input
                  :disabled="(scope.row.production && scope.row.production > 0)"
                  :min="0"
                  type="number"
                  size="mini"
                  v-model="scope.row.price"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column :label="columns.currency_rate.title" v-if="columns.currency_rate.show">
              <template slot-scope="scope">
                <el-input
                  :min="0"
                  type="number"
                  size="mini"
                  v-model="scope.row.currency_rate"
                  :disabled="checkCurrency(scope.row)"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column :label="columns.currency_id.title" v-if="columns.currency_id.show">
              <template slot-scope="scope">
                <select-currency
                  :size="'mini'"
                  :placeholder="columns.currency_id.title"
                  :id="scope.row.currency_id"
                  v-model="scope.row.currency_id"
                  @input="changeCurrency(scope.row)"
						      ref="currencySelect"
                ></select-currency>
              </template>
            </el-table-column>

            <el-table-column :label="columns.profit.title + ' %'" v-if="columns.profit.show" width="80">
              <template slot-scope="scope">
                <crm-only-number-input
                  v-model="scope.row.profit"
                  :inputValue="scope.row.profit"
                  @c-change="checkMinProfit(scope.row.profit, scope.row)"
                  :size="'mini'"
                  :maxValue="99"
                ></crm-only-number-input>
              </template>
            </el-table-column>

            <el-table-column :label="columns.discount.title + ' %'" v-if="columns.discount.show" width="80">
              <template slot-scope="scope">
                <el-input
                  :min="0"
                  :max="99"
                  type="number"
                  size="mini"
                  @change="noMoreThan(scope.$index)"
                  v-model="scope.row.discount"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column :label="columns.nds.title + ' %'" v-if="columns.nds.show" width="80">
              <template slot-scope="scope">
                <el-input
                  :min="0"
                  :max="99"
                  type="number"
                  size="mini"
                  @change="noMoreThan(scope.$index)"
                  v-model="scope.row.nds"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column :label="columns.color.title" v-if="columns.color.show">
              <template slot-scope="scope">
                  <select-color
                    :size="'mini'"
                    :placeholder="columns.color.title"
                    :id="scope.row.color_id"
                    :allowed_colors="scope.row.colors"
                    :model_id="scope.row.product_model_id"
                    v-model="scope.row.color_id"
                  >
                  </select-color>
              </template>
            </el-table-column>

            <el-table-column width="80">
              <template slot="header" slot-scope="scope">
                <i class="el-icon-s-tools"></i>
              </template>
              <template slot-scope="scope">
                <el-button @click="childProductListOpen(scope.row, scope.$index)" type="primary" icon="el-icon-s-tools" size="mini"></el-button>
              </template>
            </el-table-column>

            <el-table-column :label="columns.package.title" v-if="columns.package.show">
              <template slot-scope="scope">
                <select-package
                  :size="'mini'"
                  :placeholder="columns.package.title"
                  :id="scope.row.package_id"
                  :allowed_packages="scope.row.packages"
                  v-model="scope.row.package_id"
                ></select-package>
              </template>
            </el-table-column>

            <el-table-column :label="columns.total.title" v-if="columns.total.show">
              <template slot-scope="scope">
                {{ productRowTotal(scope.row) | formatMoney(2)}}
              </template>
            </el-table-column>
            
            <el-table-column width="50" v-if="columns.delete.show">
              <template slot-scope="scope">
                <i
                  @click="removeFromDealProductsList(scope.row.id, scope.$index)"
                  class="el-icon-delete icon__delete"
                ></i>
              </template>
            </el-table-column>
          </el-table>
        </el-col>

        <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12" class="mt-2">
					<div class="manager__profit">
						<span style="text-indent:50px; font-size:18px;">{{ $t('message.profit_user') }}: </span>
						<span style="text-indent:15px; font-size:18px; font-weight:bold;">{{calculateProfitManagerForProduct}}</span>
					</div>
			  </el-col>

		 	  <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12" class="mt-2">
					<div class="filial__product">
						<select-filial-product
              :filial_id="filial_id"
              :size="'medium'"
              :placeholder="$t('message.search_by_product_and_category')"
              :id="product_id"
              v-model="product_id"
              @get_selected_product="get_selected_product"
              :class="'w-100'"
              >
						</select-filial-product>
					</div>
			  </el-col>
    </el-row>
       
    <el-row class="mt-5" v-if="!change_in_deal_modal">
      <el-button type="primary" @click="submit()" size="small" round v-loading="loadingButton"
        v-if="permissions.some(per => per.slug == 'dealProducts.create')"
        >{{$t('message.save')}}
      </el-button>
      <el-button
        type="success"
        @click="dealOffersCreate"
        size="small"
        round
        >{{ $t('message.kp') }}
      </el-button>
    </el-row>

    <div class="app-modal app-modal__full modal-color-bg">
      <el-drawer
        :with-header="false"  
        :visible.sync="drawerServices" 
        size="50%"
        :append-to-body="true"
        >
          <div class="app-modal__box">
            <div class="app-modal__in">
              <div class="app-modal__header d-flex f-between p-5 m-title-modal">
                <p class="large--title m-0"> {{ $t("message.services") }}</p>
                <div>
                  <crm-store-update-close
                      :permission="$options.name"
                      :button_type="'store'"
                      :loading="loadingButton"
                      @c-submit="submitService(true)"
                      @c-close="closeService()"
                  ></crm-store-update-close>
                </div>
              </div>
            </div>
            <!-- app-modal__header end -->

            <div class="app-modal__body p-5 pb-0">
              <div class="timeline-items__right rounded-sm w-100 p-4">
                  <el-table
                    :data="selectedProductServices"
                    show-summary 
                    :summary-method="getSummariesForServices" 
                    border
                    style="width: 100%">
                      <el-table-column
                        prop="name"
                        :label="$t('message.name')"
                        width="180">
                        <template slot-scope="scope">
                          {{ scope.row.name }}
                        </template>
                      </el-table-column>
                      <el-table-column
                        prop="money_amount"
                        :label="$t('message.price')">
                        <template slot-scope="scope">
                          <el-input
                            :min="0"
                            type="number"
                            size="mini"
                            v-model="scope.row.money_amount"
                          ></el-input>
                        </template>
                        
                      </el-table-column>
                      <el-table-column
                        prop="currency_rate"
                        :label="$t('message.rate')">
                        <template slot-scope="scope">
                          <el-input
                            :min="0"
                            type="number"
                            size="mini"
                            v-model="scope.row.currency_rate"
                            :disabled="checkCurrency(scope.row)"
                          ></el-input>
                        </template>
                      </el-table-column>
                      <el-table-column
                        prop="currency_id"
                        :label="$t('message.currency')">
                        <template slot-scope="scope">
                          {{ getCurrencyCodeById(scope.row) }}
                        </template>
                      </el-table-column>
                      <el-table-column
                        prop="currency_id"
                        :label="$t('message.money_amount')">
                        <template slot-scope="scope">
                          {{ (scope.row.money_amount * scope.row.currency_rate) | formatMoney(3)}}
                        </template>
                      </el-table-column>
                  </el-table>
              </div>
            </div>
            <!-- end app-modal__body -->
          </div>
      </el-drawer>
    </div>

    <el-drawer
      :class="mode ? 'l-modal-style' : 'd-modal-style'"
        :with-header="false"
        :visible.sync="drawerChildProduct"
        :append-to-body="true"
        ref="drawerChildProduct"
        size="100%"
        @opened="drawerOpened('drawerChildProductChild')"
        @closed="drawerClosed('drawerChildProductChild')"
    >
        <div>
            <child-products
                :deal_id="deal_id"
                :filial_id="filial_id"
                :selectedRow="selectedRow"
                @setChildProducts="setChildProducts"
                @updateParentPriceAndRemoveProduct="updateParentPriceAndRemoveProduct"
                :product_calculation_type_id="product_calculation_type_id"
                ref="drawerChildProductChild"
                drawer="drawerChildProduct"
            >
            </child-products>
        </div>
    </el-drawer>
  </div>
  <!-- </el-tab-pane> -->
</template>

<script>
import selectFilialProduct from "@/components/inventory/select-filial-product";
import selectCurrency from "@/components/inventory/select-currency";
import selectPackage from "@/components/inventory/select-package";
import selectColor from "@/components/inventory/select-color";
import selectKpTemplate from "@/components/inventory/select-kp-template";
import selectServices from "@/components/multiSelects/multi-select-product-services";
import childProducts from "./deal-product-childs";
import CrmOnlyNumberInput from '@/components/crm/crm-only-number-input';

import { formatMoney } from "@/filters/index";

import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";
import show from "@/utils/mixins/show";
import child_list from "@/utils/mixins/child_list";
import { formatNumber } from "@/filters/index";

export default {
  components: {
    selectFilialProduct,
    selectCurrency,
    selectPackage,
    selectColor,
    selectKpTemplate,
    selectServices,
    childProducts,
    CrmOnlyNumberInput
  },
  mixins: [show, form, drawer, child_list],
  props: {
    filial_id: {
      default: null,
    },
    deal_id: {
      default: null,
    }, 
    product_calculation_type_id: {
      default: null,
    }, 
    deal_calculate_money: {
      default: 'deal',
    },
    change_in_deal_modal: {
      default: false
    },
    min_profit:{
      default: null
    },
    client_status: {
      default: false
    }
  },
  data() {
    return {
      dealProductsList: [],
      product_id: null,
      updateProducts: false,
      drawerServices: false,
      selectedProductServices: [],
      selectedProductIndex: null,
      selectedRow: {},
      drawerChildProduct: false,
      url: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
      srcList: [
        'https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg',
        // 'https://fuss10.elemecdn.com/1/8e/aeffeb4de74e2fde4bd74fc7b4486jpeg.jpeg'
      ]
    };
  },
  watch: {
    deal_id: {
      handler: function (deal_id) {
        this.updateList();
        this.updateFeeList();
      },
      immediate: true,
      deep: true,
    },
    dealProductsList: {
      handler: function (newVal) {
        if(this.change_in_deal_modal){
          this.$emit('input', newVal);
        }
      },
      immediate: true,
      deep: true,
    },
  },
  computed: {
    ...mapGetters({
      mode: "MODE",
      permissions: "auth/permissions",
      getProduct: "products/model",
      model: "dealProducts/model",
      dealProducts: "dealProducts/dealProducts",
      rules: "dealProducts/rules",
      columns: "dealProducts/columns",
      currencies: "currency/inventory",
      services: "productServices/inventory",
      getFeeList: "dealProductAdditionalFeeType/list",
    }),

    totalAmountWithoutNDSAndDiscount: function () {
      let total = 0;
      this.dealProductsList.forEach((product) => {
        total += parseFloat(product.quantity) * parseFloat(product.price) * parseFloat(product.currency_rate);
      });
      return total;
    },
    profitAmount: function () {
      let total = 0;
      this.dealProductsList.forEach((product) => {
        total += (parseFloat(product.currency_rate) * parseFloat(product.quantity) * parseFloat(product.price) * parseFloat(product.profit)) / 100;
      });
      return total;
    },
    discountAmount: function () {
      let total = 0;
      this.dealProductsList.forEach((product) => {
        total += (parseFloat(product.currency_rate) * parseFloat(product.quantity) * parseFloat(product.price) * parseFloat(product.discount)) / 100;
      });
      return total;
    },
    NDSAmount: function () {
      let total = 0;
      this.dealProductsList.forEach((product) => {
        total += (parseFloat(product.currency_rate) * parseFloat(product.quantity) * parseFloat(product.price) * parseFloat(product.nds)) / 100;
      });
      return total;
    },
    totalAmount: function () {
      let total = 0;
      this.dealProductsList.forEach((product) => {
        let prodcutServiceCost = 0;
        if(product.serviceList && product.serviceList.length > 0){
          let moneyList = product.serviceList.map(el => (parseFloat(el.money_amount)*parseFloat(el.currency_rate)));
          prodcutServiceCost = moneyList.reduce((acc, price) => parseFloat(acc) + parseFloat(price));
        }
        total += parseFloat(product.quantity) * (parseFloat(prodcutServiceCost) + (parseFloat(product.currency_rate) * parseFloat(product.price) * (100 - parseFloat(product.discount) + parseFloat(product.nds))/100));
      });
      return total;
    },
	 calculateProfitManagerForProduct() {
    let currency_symbol = '';
		let self = this;
		let total = 0;
	
		if(!_.isEmpty(this.parent().type_rules) && !_.isEmpty(this.dealProductsList)){
			if (this.$refs['currencySelect']) currency_symbol = this.$refs['currencySelect'].currency_symbol;
			_.forEach(this.dealProductsList, product => {
				if (product.profit) {
					let profit = _.find(this.parent().type_rules, o => o.from <= product.profit && o.until >= product.profit && o.first_deal == self.client_status);
					total += (parseFloat(product.price) * product.currency_rate  * parseFloat(product.quantity) * parseFloat(product.profit) * parseFloat(profit.bonus) / 10000);
				}
			});
		}
		return formatNumber(total, 1) + ' $';
    },
  },
  methods: {
   ...mapActions({
      save: "dealProducts/store",
      update: "dealProducts/update",
      updateColumn: "dealProducts/updateColumn",
      show: "dealProducts/show",
      showProduct: "products/show",
      empty: "dealProducts/empty",
      destroy: "dealProducts/destroy",
      updateCurrencyInventory: "currency/inventory",
      updateFeeInventory: "dealProductAdditionalFeeType/index",
   }),
	parent(){
		return this.$parent.$parent;
	},
  updateFeeList(){
    this.updateFeeInventory();
  },
	checkMinProfit(profit, row){
		let min_profit = this.min_profit ? this.min_profit : row.min_profit;
		if ((parseFloat(profit) < min_profit) || !profit){
			this.$set(row, 'profit', min_profit);
		}
	},
  updateParentPriceAndRemoveProduct(data){
    this.$set(this.dealProductsList[this.selectedProductIndex], 'price', parseFloat(data.price));
    this.dealProductsList[this.selectedProductIndex].child_products.splice(data.index, 1);
  },
   setChildProducts(item){
      if(this.selectedProductIndex >= 0){
        this.$set(this.dealProductsList[this.selectedProductIndex], 'calculation_type_id', item.calculation_type_id);
        this.$set(this.dealProductsList[this.selectedProductIndex], 'quantity', parseFloat(item.last_quantity));
        this.$set(this.dealProductsList[this.selectedProductIndex], 'price', parseFloat(item.last_price));
        this.$set(this.dealProductsList[this.selectedProductIndex], 'profit', parseFloat(item.profit));
        this.$set(this.dealProductsList[this.selectedProductIndex], 'child_products', item.products);
        this.$set(this.dealProductsList[this.selectedProductIndex], 'feeList', item.feeList);
      }
      this.selectedProductIndex = null;
      this.selectedRow = {};
    },
    childProductListOpen(row, index){
      this.selectedProductIndex = index;
      this.selectedRow = JSON.parse(JSON.stringify(this.dealProductsList[index]));
      this.drawerChildProduct = true;
    },
    noMoreThan(index) {
        if(this.dealProductsList[index].profit >= 100){
          this.dealProductsList[index].profit = 99;
        }
        if(this.dealProductsList[index].profit < 0){
          this.dealProductsList[index].profit = 0;
        }
        if(this.dealProductsList[index].nds >= 100){
          this.dealProductsList[index].nds = 99;
        }
        if(this.dealProductsList[index].nds < 0){
          this.dealProductsList[index].nds = 0;
        }
        if(this.dealProductsList[index].discount >= 100){
          this.dealProductsList[index].discount = 99;
        }
         if(this.dealProductsList[index].discount < 0){
          this.dealProductsList[index].discount = 0;
        }
    },
    checkCurrency(row){
      let currency = this.currencies.find(curr => curr.id === row.currency_id);  
      if(currency && currency.active){
        return true;
      }
      return false;
    },

    check: function (column, event) {
      this.updateColumn({key: column, value: event,})
    },

    changeCurrency(row){
      if(row.currency_id){
        let currency = this.currencies.find(el => el.id === row.currency_id);
        if(currency){
          let price = _.round((parseFloat(row.currency_rate) * parseFloat(row.price) / parseFloat(currency.rate)), 2);
          this.$set(row, 'currency_rate', currency.rate);
          this.$set(row, 'price', price);
        }
      }
    },
    getSummaries(param) {
        const { columns } = param;
        const sums = [];
        columns.forEach((column, index) => {
          if (index === 0) {
            sums[index] = this.$t('message.total');
            return;
          }
          if (column.label === this.columns.price.title) {
            sums[index] = formatMoney(this.totalAmountWithoutNDSAndDiscount, 2);
            return;
          }
          if (column.label === (this.columns.profit.title + ' %')) {
            sums[index] = formatMoney(this.profitAmount, 2);
            return;
          }
          if (column.label === (this.columns.discount.title + ' %')) {
            sums[index] = formatMoney(this.discountAmount, 2);
            return;
          }
          if (column.label === (this.columns.nds.title + ' %')) {
            sums[index] = formatMoney(this.NDSAmount, 2);
            return;
          }
          if (column.label === this.columns.total.title) {
            sums[index] = formatMoney(this.totalAmount, 2);
            return;
          }
        });

        return sums;
    },
    getSummariesForServices(param){
      const { columns, data } = param;
        const sums = [];
        columns.forEach((column, index) => {
          if (index === 0) {
            sums[index] = this.$t('message.total');
            return;
          }
          if(column.label === this.$t('message.money_amount')){
            let total = data.map(serv => parseFloat(serv.money_amount*serv.currency_rate));
            if(total.length > 0){
              sums[index] = formatMoney(total.reduce((all_money, money) => all_money+money), 3);
            }else{
              sums[index] = 0;
            }
            return;
          }
      });

      return sums;
    },

    checkRowServices(row){
      if(row.services && row.services.length > 0){
        return false;
      }
      return true;
    },
    showServices(row, index){
      this.drawerServices = true;
      this.selectedProductServices = JSON.parse(JSON.stringify(row.serviceList));
      this.selectedProductIndex = index;
    },

    getCurrencyCodeById(row){
      let currency = this.currencies.find(el => el.id === row.currency_id);
      if(currency){
        return currency.symbol
      }
      return '';
    },
    productServiceChange(data, index){
      if(this.dealProductsList[index].serviceList){
        this.$set(this.dealProductsList[index], 'serviceList', this.dealProductsList[index].serviceList.filter(el => data.includes(el.id)));
      }else{
        this.$set(this.dealProductsList[index], 'serviceList', []);
      }

      let list_ids = this.dealProductsList[index].serviceList.map(el => el.id);
      let newServices = data.filter(item => !list_ids.includes(item) )
      if(newServices.length > 0){
        newServices.forEach(el => {
          let service = JSON.parse(JSON.stringify(this.services.find(e => e.id === el)));
          if(service){
            let currency = this.currencies.find(el => el.id === service.currency_id);
            currency = currency ? currency.rate : 0; 
            this.$set(service, 'currency_rate', currency);
            this.dealProductsList[index].serviceList.push(service);
          }
        });
      }
      this.dealProductsList[index].serviceList.sort((a, b) => a.id - b.id);
    },

    submitService(close){
      if(this.selectedProductIndex >= 0){
        this.$set(this.dealProductsList[this.selectedProductIndex], 'serviceList', this.selectedProductServices);
      }
      this.closeService();
    },
    closeService(){
      this.selectedProductServices = [];
      this.selectedProductIndex = null;
      this.drawerServices = false;
    },
 
    get_selected_product(selected_product_id){
      if(selected_product_id){
        this.addToDealProductsList(selected_product_id);
      }
    },
    dealOffersCreate() {
      if(this.dealProductsList.length > 0){
        this.$emit('dealOffersCreate', {drawer: true, productList: this.dealProductsList})
      }else{
        this.$message({
            type: "warning",
            message: this.$t('message.product_list_is_empty'),
        });
      }
    },
    productRowTotal(product) {
        let rowTotal = 0;
        let prodcutServiceCost = 0;
        if(product.serviceList && product.serviceList.length > 0){
          let moneyList = product.serviceList.map(el => (parseFloat(el.money_amount) * parseFloat(el.currency_rate)));
          prodcutServiceCost = moneyList.reduce((acc, price) => parseFloat(acc) + parseFloat(price));
        }
        rowTotal = (parseFloat(product.currency_rate) * parseFloat(product.price) + parseFloat(prodcutServiceCost)) * parseFloat(product.quantity) * parseFloat(100 - parseFloat(product.discount) + parseFloat(product.nds)) / 100;
        return parseFloat(rowTotal || 0);
    },

    addToDealProductsList(product_id) {
      this.showProduct(product_id).then(() => {
        let item = {};

        let product_children = JSON.parse(JSON.stringify(this.getProduct.child_products));
        product_children.forEach(element => {
            element.id = null;
            let rate = 1;
            if(element.selling_price_currency_id){
              let currency = this.currencies.find(el => el.id === element.selling_price_currency_id)
              rate = currency ? currency.rate : 1;
            }
            element.currency_rate = rate;
        });
        if(product_children && product_children.length > 0){
          item.child_products = product_children;
        }else{
          item.child_products = [];
        }

        let product_feeList = JSON.parse(JSON.stringify(this.getProduct.feeList));
        product_feeList.forEach(element => {
            element.id = null;
        });
        if(product_feeList && product_feeList.length > 0){
          item.feeList = product_feeList;
        }else{
          item.feeList = [];
        }

        let currency_id = this.getProduct.selling_price_currency.id;
        let rate = null
        if(currency_id){
          let currency = this.currencies.find(el => el.id === currency_id)
          if(currency){
            rate = currency.rate;
          }
        }
        let profit = 0;
        if(this.min_profit && parseFloat(this.min_profit || 0) > parseFloat(this.getProduct.profit || 0)){
          profit = this.min_profit;
        }else if (this.getProduct.profit){
          profit = this.getProduct.profit;
        }
        item.profit = profit;

        item.id = null;
        item.deal_id = this.deal_id;
        item.product_id = product_id;
        item.currency_id = currency_id;
        item.production = this.getProduct.production;
        item.currency_rate = rate;
        item.product = JSON.parse(JSON.stringify(this.getProduct));
        item.quantity = 1;
        item.discount = 0;
        item.nds = 0;
        item.color_id = null;
        item.services = [];
        item.serviceList = [];
        item.colors = this.getProduct.allowed_colors;
        item.package_id = null;
        item.packages = this.getProduct.allowed_packages;
        item.service_for_unit = true;
        item.calculation_type_id = this.getProduct.calculation_type_id ? this.getProduct.calculation_type_id : this.product_calculation_type_id;
        if(this.getProduct.production > 0 && this.getProduct.child_products && this.getProduct.child_products.length == 0){
          item.price = 0;
        }else if(this.getProduct.child_products && this.getProduct.child_products.length > 0){
          // CALCULATES WITH OLD CURRENCY RATE
          // let price = (this.getProduct.selling_price * 100) / ( 100 + parseFloat(this.getProduct.profit));
          // price = (parseFloat(price || 0) * (1 + parseFloat(profit || 0)/100));
          // item.price = _.round(parseFloat(price || 0), 2);
          
          // CALCULATES WITH NEW SYSTEM CURRENCY RATE
          item.price = this.calculateTotals(item, profit);
        }else {
          item.price = parseFloat(this.getProduct.selling_price || 0);
        }
        
        this.dealProductsList.push(item);
      });
    },

    calculateTotals(rowProduct, profit){
        let total = 0;
        let last_price_net = 0;
        let fee_total_percentage = 0;
        rowProduct.child_products.forEach((product) => {
            let prodcutServiceCost = 0;
            if(product.serviceList && product.serviceList.length > 0){
                let moneyList = product.serviceList.map(el => (parseFloat(el.money_amount || 0) * parseFloat(el.currency_rate || 0)));
                prodcutServiceCost = moneyList.reduce((acc, price) => parseFloat(acc) + parseFloat(price || 0));
            }
            total += parseFloat(product.quantity || 0) * (parseFloat(prodcutServiceCost || 0) + parseFloat(product.currency_rate || 0) * parseFloat(product.price || 0));
            if(rowProduct.calculation_type_id != 1){ // rowProduct.calculation_type_id == 2
              last_price_net += (parseFloat(product.price || 0) + parseFloat(prodcutServiceCost || 0));
              last_price_net = last_price_net * (1 + parseFloat(product.waste || 0) / 100);
            }
        });
        if(rowProduct.feeList.length > 0){
          rowProduct.feeList.forEach(element => {
            fee_total_percentage += parseFloat(element.percentage || 0)
          });
        }
        if(rowProduct.calculation_type_id == 1){
          last_price_net =  parseFloat(total) * (1 + parseFloat(fee_total_percentage || 0)/100);
          last_price_net = parseFloat(last_price_net) / parseFloat(rowProduct.currency_rate); 

        }else {  // rowProduct.calculation_type_id == 2 
          last_price_net =  parseFloat(last_price_net) * (1 + parseFloat(fee_total_percentage || 0)/100);
          last_price_net = parseFloat(last_price_net) / parseFloat(rowProduct.currency_rate);
        }
        let last_price = (parseFloat(last_price_net || 0) * (1 + parseFloat(profit || 0)/100));
        return _.round(last_price, 2);
    },

    removeFromDealProductsList(id, index) {
      if (id) {
        this.$confirm(
            this.$t('message.Are you sure you want to uninstall the product?'),
            this.$t('message.warning'),
            {
            confirmButtonText: "Да",
            cancelButtonText: "Нет",
            type: "warning",
            }
            
        )
        .then(() => {
            this.destroy(id)
              .then((res) =>{
                this.dealProductsList.splice(index, 1);
                this.$emit('updateProdsOnClose');
                this.$alert(res);
              })
              .catch((err) => {
                  this.$alert(err);
              })
        })
        .catch(() => {
            this.$message({
                type: "warning",
                message: this.$t('message.deleting_product_canceled'),
            });
        });
      }else{
          this.dealProductsList.splice(index, 1);
      }
    },

    tableRowClassName({row, rowIndex}){
        if(row.price <= 0){
          return 'danger-row';
        }else{
          return '';
        }
    },

    submit(close = true) {
      if(this.deal_calculate_money === 'product'){
        this.$emit('listChanged');
        this.$emit('updateProductTotal', this.totalAmount);
      }
      if(this.dealProductsList.filter(el => el.price <= 0).length > 0){
        this.$notify({
            title: this.$t('message.warning'),
            type: "warning",
            offset: 130,
            message: this.$t('message.set_product_price'),
        });
      } else if (this.updateProducts) {
        this.loadingButton = true;
        this.update(this.dealProductsList)
        .then((res) => {
          this.loadingButton = false;
          this.updateList();
          this.$alert(res);
        })
        .catch((err) => {
          this.loadingButton = false;
          this.$alert(err);
        });
      } else {
        this.loadingButton = true;
        this.save(this.dealProductsList)
        .then((res) => {
          this.loadingButton = false;
          this.updateList();
          this.$alert(res);
        })
        .catch((err) => {
          this.loadingButton = false;
          this.$alert(err);
        });
      }
    },
    updateList(){
      if(this.deal_id){
		  this.loadingData = true;
        this.updateProdList(this);
      }
    },
    updateProdList: _.debounce((self) => {
      if(self.currencies.length === 0){
        self.updateCurrencyInventory();
      }
      self.show(self.deal_id)
          .then((res) => {
            self.dealProductsList = JSON.parse(JSON.stringify(self.dealProducts));
            self.updateProducts = true;
            self.loadingData = false;
          })
          .catch((err) => {
          self.loadingData = false;
          });
    }, 300),

  },
};
</script>
<style>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.custom__flex{
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}
.filial__product{
	width: 100%;
}
.manager__profit{
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
}

.el-table .success-row {
  background: #99da76;
}
.el-table .danger-row {
  background: #e9a5a9;
}

</style>
