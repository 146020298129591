<template>
  <div class="mobil-menu-left-m">
    <div class="mobil-top-header">
      <img src="/img/gocrm.png" alt="">
      <i @click="$emit('handleClick')" class="el-icon-close"></i>
    </div>
    <el-menu
      class="el-menu-vertical-demo"
      :unique-opened="true"
      @open="handleOpen"
      @close="handleClose">

      <el-menu-item index="01">
        <router-link tag="div" to="/"   class="breadcrumb__item">
          <div  @click="$emit('handleClick')">
            <i class="fa-solid fa-chart-simple icon__fontawesome alw"></i> {{$t('message.desktop')}}
          </div>
        </router-link>
      </el-menu-item>

      <el-menu-item index="02" >
        <router-link tag="div" to="/projectPortfolio"   class="breadcrumb__item">
          <div  @click="$emit('handleClick')">
            <i class="fa-solid fa-chart-simple icon__fontawesome alw"></i>
            {{ $t('message.projects') }}
          </div>
        </router-link>
      </el-menu-item>

	  <el-menu-item index="03" >
        <router-link tag="div" to="/custom_form"   class="breadcrumb__item">
            <div style="margin-left: -4px;"  @click="$emit('handleClick')">
                <i class="el-icon-discount icon__fontawesome alw"></i>
                Form
            </div>
         </router-link>
      </el-menu-item>

      <el-menu-item index="04" >
          <router-link tag="div" to="/calendar"   class="breadcrumb__item">
            <div  style="margin-left: -4px;"  @click="$emit('handleClick')">
                <i class="el-icon-date" ></i>
                {{ $t('message.calendar') }}
            </div>
         </router-link>
      </el-menu-item>



      <el-submenu  v-can-or="item.is_show" v-for="(item,index) in list" :key="index" :index="parseInt(index + 1)">
        <template slot="title">
          <i :class="item.icon"></i>
          <span>{{ $t(item.name) }}</span>
        </template>
        <el-menu-item-group>
          <el-menu-item  v-can="sub.is_show" v-for="(sub,subIndex) in item.items" :key="subIndex" :index="parseInt(`${index}-${subIndex}`)" >

            <router-link tag="div" :to="{name:sub.link}"   class="breadcrumb__item">
              <div  @click="$emit('handleClick')">{{ $t(sub.name) }} </div>
            </router-link>

          </el-menu-item>
        </el-menu-item-group>
      </el-submenu>

    </el-menu>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'GoCrmFrontedMobilLeftMenu',

  data() {
    return {
      list: [
          {
              link: false,
              name: 'message.deals',
              tooltip: 'Dashboard',
              icon: 'fa-solid fa-handshake-simple',
              open: false,
              listOne: false,
              is_show:[
                  'boards.index',
                  'deals.index',
                  'deals.archivedDeals',
                  'deals.deletedDeals',
                  'dealTypes.index',
                  'reasons.index',
                  'happyCallQuestions',
              ],
              items: [
                  {
                      name: 'message.deals',
                      link: 'deals',
                      is_show: 'boards.index'
                  },
                  {
                      name: 'message.archivedDeals',
                      link: 'archivedDeals',
                      is_show: 'deals.archivedDeals'
                  },
                  {
                      name: 'message.deletedDeals',
                      link: 'deletedDeals',
                      is_show: 'deals.deletedDeals'
                  },
                  {
                      name: 'message.dealTypes',
                      link: 'dealTypes',
                      is_show: 'dealTypes.index'
                  },
                  {
                      name: 'message.deal_reasons',
                      link: 'reasons',
                      is_show: 'reasons.index'
                  },
                  {
                      name: 'message.happyCalls',
                      link: 'happyCalls',
                      is_show: 'reasons.index'
                  },
                  {
                      name: 'message.happyCallQuestions',
                      link: 'happyCallQuestions',
                      is_show: 'happyCallQuestions.index'
                  }
              ]
          },
          {
              link: false,
              name: 'message.cash_register',
              tooltip: 'User',
              icon: 'fa-solid fa-sack-dollar',
              open: false,
              listOne: false,
              is_show:[
                  'payments.index',
                  'costTransactions.index',
                  'bankAccounts.index',
                  'currencies.index',
                  'costTypes.index'
              ],
              items: [
                  {
                      name: 'message.payments',
                      link: 'payments',
                      is_show: 'payments.index'
                  },
                  {
                      name: 'message.payemnts_for_costs',
                      link: 'costTransactions',
                      is_show: 'costTransactions.index'
                  },
                  {
                      name: 'message.costTypes',
                      link: 'costType',
                      is_show: 'costTypes.index'
                  },
                  {
                      name: 'message.bankAccounts',
                      link: 'bankAccounts',
                      is_show: 'bankAccounts.index'
                  },
                  {
                      name: 'message.currencies',
                      link: 'currency',
                      is_show: 'currencies.index'
                  },
              ]
          },
          {
              link: '#',
              name: 'message.tasks',
              tooltip: 'Messages',
              icon: 'fa-regular fa-circle-check',
              open: false,
              listOne: false,
              is_show:[
                  'taskBoards.index',
                  'tasks.archivedTasks',
                  'tasks.deletedTasks',
                  'taskReasons.index',
              ],
              items: [
                  {
                      name: 'message.taskBig',
                      link: 'tasks',
                      is_show: 'taskBoards.index'
                  },
						 {
							name: 'message.tasksMy',
							link: 'tasksMy',
							is_show: 'taskBoards.index'
						},
                  {
                      name: 'message.completed_tasks',
                      link: 'archivedTasks',
                      is_show: 'tasks.archivedTasks'
                  },
                  {
                      name: 'message.deletedTasks',
                      link: 'deletedTasks',
                      is_show: 'tasks.deletedTasks'
                  },
                  {
                      name: 'message.taskReasons',
                      link: 'taskReasons',
                      is_show: 'taskReasons.index'
                  },
                  {
                      name: 'message.calendar',
                      link: 'calendar',
                      is_show: 'taskReasons.index'
                  }
              ]
          },
          {
              link: '#',
              name: 'message.calls',
              tooltip: 'Analytics',
              icon: 'fa-solid fa-phone',
              open: false,
              listOne: false,
              is_show:[
                  'sipAccounts.index',
                  'phoneContacts.index',
                  'phoneNumberForSipAccountOutsideCall.index',
                  'callHistory.index'
              ],
              items: [
                  {
                      name: 'message.call_history',
                      link: 'callHistories',
                      is_show: 'callHistory.index'
                  },
                  {
                      name: 'message.phoneContacts',
                      link: 'phoneContacts',
                      is_show: 'phoneContacts.index'
                  },
                  {
                      name: 'message.sipAccounts',
                      link: 'sipAccount',
                      is_show: 'sipAccounts.index'
                  },
                  {
                      name: 'message.phoneNumberForSipAccountOutsideCall',
                      link: 'phoneNumberForSipAccountOutsideCall',
                      is_show: 'phoneNumberForSipAccountOutsideCall.index'
                  }
              ]
          },
          {
              link: '#',
              name: 'message.exhibitions',
              tooltip: 'Analytics',
              icon: 'fa-solid fa-handshake-simple',
              open: false,
              listOne: false,
              is_show:[
                  'exhibitions.index',
                  'exhibitiontypes.index'
              ],
              items: [
                  {
                      name: 'message.exhibitions',
                      link: 'exhibitions',
                      is_show: 'exhibitions.index'
                  },
                  {
                      name: 'message.exhibitionTypes',
                      link: 'exhibitionTypes',
                      is_show: 'exhibitiontypes.index'
                  },
              ]
          },
          {
              link: '#',
              name: 'message.counterparties',
              tooltip: 'Files',
              icon: 'fa-solid fa-user-group',
              open: false,
              listOne: false,
              is_show:[
                  'clients.index'
              ],
              items: [
                  {
                      name: 'message.clients',
                      link: 'clients',
                      is_show: 'clients.index'
                  }
              ]
          },
          {
              link: '#',
              name: 'message.h_r',
              tooltip: 'Order',
              icon: 'fa-solid fa-user-gear',
              open: false,
              listOne: false,
              is_show:[
                  'users.index',
                  'roles.index',
                  'permissions.index',
						'departments.index',
              ],
              items: [
                  {
                      name: 'message.users',
                      link: 'users',
                      is_show: 'users.index'
                  },
                  {
                      name: 'message.roles',
                      link: 'roles',
                      is_show: 'roles.index'
                  },
                  {
                      name: 'message.permissions',
                      link: 'permissions',
                      is_show: 'permissions.index'
                  },
						{
                    name: 'message.departments',
                    link: 'departments',
                    is_show: 'departments.index'
                  }
              ]
          },
          {
              link: '#',
              name: 'message.settings',
              tooltip: 'Saved',
              icon: 'fa-solid fa-gear',
              open: false,
              listOne: false,
              is_show:[
                  'dealStages.index',
                  'paymentTypes.index',
                  'sources.index',
                  'companies.index',
                  'filials.index',
                  'countries.index',
                  'cities.index',
                  'categories.index',
                  'clientTypes.index',
                  'dealGoals.index',
                  'dealConditions.index',
                  'shipmentTerms.index',
                  'kPExampleTemplates.index',
                  'colors.index',
                  'packages.index',
              ],
              items: [
                  {
                      name: 'message.kpTemplates',
                      link: 'kpTemplate',
                      is_show: 'kPExampleTemplates.index'
                  },
                  {
                      name: 'message.dealStages',
                      link: 'dealStage',
                      is_show: 'dealStages.index'
                  },
                  {
                      name: 'message.paymentTypes',
                      link: 'paymentTypes',
                      is_show: 'paymentTypes.index'
                  },
                  {
                      name: 'message.sources',
                      link: 'sources',
                      is_show: 'sources.index'
                  },
                  {
                      name: 'message.companies',
                      link: 'companies',
                      is_show: 'companies.index'
                  },
                  {
                      name: 'message.filial',
                      link: 'filial',
                      is_show: 'filials.index'
                  },
                  {
                      name: 'message.countries',
                      link: 'country',
                      is_show: 'countries.index'
                  },
                  {
                      name: 'message.cities',
                      link: 'city',
                      is_show: 'cities.index'
                  },
                  {
                      name: 'message.categories',
                      link: 'category',
                      is_show: 'categories.index'
                  },
                  {
                      name: 'message.clientTypes',
                      link: 'clientTypes',
                      is_show: 'clientTypes.index'
                  },
                  {
                      name: 'message.dealGoals',
                      link: 'dealGoals',
                      is_show: 'dealGoals.index'
                  },
                  {
                      name: 'message.dealConditions',
                      link: 'dealConditions',
                      is_show: 'dealConditions.index'
                  },
                  {
                      name: 'message.shipmentTerms',
                      link: 'shipmentTerms',
                      is_show: 'shipmentTerms.index'
                  },
                  {
                      name: 'message.colors',
                      link: 'colors',
                      is_show: 'colors.index'
                  },
                  {
                      name: 'message.packages',
                      link: 'packages',
                      is_show: 'packages.index'
                  }
              ]
          },
          {
              link: '#',
              name: 'message.product_settings',
              tooltip: 'Setting',
              icon: 'fa-solid fa-bag-shopping',
              open: false,
              listOne: false,
              is_show:[
                  'products.index',
                  'measurements.index',
                  'productSizes.index',
                  'productModels.index',
              ],
              items: [
                  {
                      name: 'message.products',
                      link: 'product',
                      is_show: 'products.index'
                  },
                  {
                      name: 'message.services',
                      link: 'productServices',
                      is_show: 'productServices.index'
                  },
                  {
                      name: 'message.measurements',
                      link: 'measurements',
                      is_show: 'measurements.index'
                  },
                  {
                      name: 'message.productSizes',
                      link: 'productSizes',
                      is_show: 'productSizes.index'
                  },
                  {
                      name: 'message.productModels',
                      link: 'productModels',
                      is_show: 'productModels.index'
                  }
              ]
          }
      ],
    };
  },
  computed: {
        ...mapGetters({
            mode: "MODE",
        }),
    },
};
</script>

<style lang="scss">
.breadcrumb__item.router-link-exact-active .alw,
.mobil-menu-left-m .el-menu-item.is-active,
.mobil-menu-left-m .router-link-exact-active div{
  color: #409EFF !important;
}
.mobil-menu-left-m{
  .el-menu {
    border: none;
    background-color: initial;
  }
  // .el-submenu.is-active .el-submenu__title,
 .el-submenu.is-opened  .el-submenu__title{
    background-color: #409EFF;
    color: #fff;
    i{
        color: #fff;
    }
  }

  .el-submenu__title:focus,
  .el-submenu__title:hover,
  .el-menu-item:focus,
  .el-menu-item:hover {
    outline: 0;
    background-color: initial;
  }
  .alw{
    margin-right: 8px;
  }
  .el-submenu__title i{
    margin-right: 10px;
  }
  .el-menu-item,
  .el-submenu__title {
    height: 46px;
    line-height: 45px;
  }
}
.mobil-top-header{
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    img{
      width: 130px;
    }
    i{
      margin-top: -10px;
      font-size: 1.2rem;
    }
}

</style>
