import { i18n } from '@/utils/i18n';

export const columns = {
    id : {
        show: true,
        title: i18n.t('message.n'),
        sortable: true,
        column: 'id'
    },
    user_id : {
        show: true,
        title: i18n.t('message.user_id'),
        sortable: true,
        column: 'user_id'
    },
	 filial : {
		show: true,
		title: i18n.t('message.filial'),
		sortable: true,
		column: 'filial'
	  },
    meeting_goal : {
        show: true,
        title: i18n.t('message.meeting_goal'),
        sortable: true,
        column: 'meeting_goal'
    },
    type_id : {
        show: true,
        title: i18n.t('message.workMeetingType'),
        sortable: true,
        column: 'type_id'
    },
    detail : {
        show: true,
        title: i18n.t('message.detail'),
        sortable: true,
        column: 'detail'
    },
    begin_date : {
        show: true,
        title: i18n.t('message.begin_date'),
        sortable: true,
        column: 'begin_date'
    },
    end_date : {
        show: true,
        title: i18n.t('message.end_date'),
        sortable: true,
        column: 'end_date'
    },
    comment : {
        show: false,
        title: i18n.t('message.comment'),
        sortable: true,
        column: 'comment'
    },
	 meeting_format : {
		show: true,
		title: i18n.t('message.meeting_format'),
		sortable: true,
		column: 'meeting_format'
  },
	link : {
		show: true,
		title: i18n.t('message.link'),
		sortable: true,
		column: 'link'
	},

    created_at : {
        show: false,
        title: i18n.t('message.created_at'),
        sortable: true,
        column: 'created_at'
    },
    updated_at : {
        show: false,
        title: i18n.t('message.updated_at'),
        sortable: true,
        column: 'updated_at'
    },   
    settings : {
        show: true,
        title: i18n.t('message.settings'),
        sortable: false,
        column: 'settings'
    },

};
