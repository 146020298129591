import request from '@/utils/request';

export function index(params) {
    return request({
        url: '/callHistory/getUserCallHistory',
        method: 'get',
        params
    })
}

export function bindingCallHistory(data) {
    return request({
        url: '/callHistory/bindingCallHistory',
        method: 'post',
        data
    })
}
