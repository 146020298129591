<template>
  <div>
    <el-form-item
      :label="$t('message.company')"
      class="label_mini"
    >
    <div class="clent-pulus d-flex">
        <el-select
          :value="selected"
          @input="dispatch"
          :placeholder="placeholder || $t('message.company')"
          filterable
          clearable
          :size="size"
          class="w-100"
        >
          <el-option
            v-for="(company, index) in companies"
            :key="'companies-' + index"
            :label="company.name"
            :value="company.id"
          ></el-option>
        </el-select>
        <el-button :size="size" class="ml-2" type="primary" icon="el-icon-plus" @click="drawerCreate = true"></el-button>
      </div>
    </el-form-item>

    <div>
      <el-drawer
          :class="mode ? 'l-modal-style' : 'd-modal-style'"
          :with-header="false"
          :visible.sync="drawerCreate"
          :append-to-body="true"
          ref="drawerCreate"
          size="70%"
          class="body_scroll_70 bg-se" 
          @opened="drawerOpened('drawerCreateChild')"
          @closed="drawerClosed('drawerCreateChild')"
      >
          <div>
              <create
                  ref="drawerCreateChild"
                  drawer="drawerCreate"
                  @createdCompany="afterCreated"
              >
              </create>
          </div>
      </el-drawer>
    </div>
  </div>
</template>
<script>
import create from "@/views/companies/components/crm-create";
import { mapGetters, mapActions } from "vuex";
import list from "@/utils/mixins/child_list";

export default {
  mixins: [list],
  components: { create },
  props: {
    size: {
      default: "small",
    },
    placeholder: {
      default: null,
    },
    id: {
      default: null,
    },
  },
  watch: {
    id: {
      handler: function () {
        this.selected = this.id;
      },
      immediate: true,
    },
  },
  data() {
    return {
      selected: null,
    };
  },
  mounted() {
    if (this.companies && this.companies.length === 0) this.updateInventory();
  },
  computed: {
    ...mapGetters({
      companies: "company/inventory",
      mode: "MODE"
    }),
  },
  methods: {
    ...mapActions({
      updateInventory: "company/inventory",
    }),
    showModal(modal) {
      this.$modal.show(modal);
    },
    afterCreated(company) {
        this.dispatch(company.id);
    },
    dispatch(e) {
      this.$emit("input", e);
      this.selected = e;
    },
  },
};
</script>
